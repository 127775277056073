import { toast } from 'react-toastify';

import { all, fork, takeEvery } from 'redux-saga/effects';

import types from '../actions/types';

const showSuccessToast = ({ message }) => {
  toast.success(message);
};

const showErrorToast = ({ message }) => {
  toast.error(message);
};

const showInfoToast = ({ message }) => {
  toast.info(message);
};

const showWarningToast = ({ message }) => {
  toast.warning(message);
};

function* watchShowSuccessToast() {
  yield takeEvery(types.SHOW_SUCCESS_TOAST, showSuccessToast);
}

function* watchShowErrorToast() {
  yield takeEvery(types.SHOW_ERROR_TOAST, showErrorToast);
}

function* watchShowInfoToast() {
  yield takeEvery(types.SHOW_INFO_TOAST, showInfoToast);
}

function* watchShowWarningToast() {
  yield takeEvery(types.SHOW_WARNING_TOAST, showWarningToast);
}

function* toastSaga() {
  yield all([
    fork(watchShowSuccessToast),
    fork(watchShowErrorToast),
    fork(watchShowInfoToast),
    fork(watchShowWarningToast),
  ]);
}

export default toastSaga;
