import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, FormControl, TextField, Typography } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import trLocale from 'date-fns/locale/tr';

import {
  createErrorToast,
  createSuccessToast,
} from '@app-actions/toastActions';
import { createAd } from '@app-api/ads';
import SelectJob from '@app-components/Select/SelectJob';
import Spinner from '@app-components/Spinner';
import TypographyBold from '@app-components/TypographyBold';
import { selectCurrentUser } from '@app-redux/selectors/user';
import { formatISODate } from '@app-shared/utils';

import useStyles from './styles';

const CreateAdModal = ({ open, handleOpen }) => {
  const dispatch = useDispatch();

  const { pnumber, email, hidePnumber } = useSelector(selectCurrentUser);

  const queryClient = useQueryClient();

  const { projectid } = useParams();

  const handleClose = () => {
    handleOpen(false);
  };

  const {
    mutate: createAdRequest,
    isLoading,
    isError,
  } = useMutation(createAd, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project', projectid]);
      handleClose();
      dispatch(
        createSuccessToast({
          message: 'İlan başarıyla oluşturuldu',
        }),
      );
    },
  });

  const [editedPhoneNumber, setEditedPhoneNumber] = useState(
    !hidePnumber && pnumber,
  );
  const [editedEmail, setEditedEmail] = useState(email);
  const [category, setCategory] = useState(null);
  const [lastday, setLastday] = useState(formatISODate(new Date()));
  const [pickerDate, setPickerDate] = useState(new Date());
  const [note, setNote] = useState('');

  const classes = useStyles();

  const handleCategorySelect = selectedCategory => {
    setCategory(selectedCategory);
  };

  const handleDateChange = newValue => {
    setPickerDate(newValue);
    setLastday(formatISODate(newValue));
  };

  const handleSubmit = () => {
    const createAdObj = {
      pnumber: editedPhoneNumber,
      email: editedEmail,
      category,
      lastday,
      note,
      projectID: projectid,
    };

    if (Object.values(createAdObj).some(value => value === undefined)) {
      return;
    }

    createAdRequest({
      details: createAdObj,
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    dispatch(
      createErrorToast({
        message: 'İlan oluşturulurken hata oluştu',
      }),
    );
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent>
          <div className={classes.outer}>
            <div className={classes.inner}>
              {/* KATEGORİ */}
              <div className={classes.row}>
                <Typography className={classes.title} variant="h4">
                  Aranan pozisyon
                </Typography>
                <FormControl className={classes.jobFormControl}>
                  <SelectJob
                    onSelect={handleCategorySelect}
                    placeholder="Meslek seçin"
                  />
                </FormControl>
              </div>

              <br />
              {/* SON BAŞVURU GÜNÜ */}
              <div className={classes.row}>
                <Typography className={classes.title} variant="h4">
                  Son başvuru günü
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={trLocale}
                >
                  <DesktopDatePicker
                    inputFormat="dd.MM.yyyy"
                    value={pickerDate}
                    minDate={new Date()}
                    onChange={handleDateChange}
                    InputProps={{ disableUnderline: true }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.dateField,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className={classes.line} />
              <br />
              <TypographyBold variant="h3">İletişim</TypographyBold>
              <Typography variant="h5">
                İlanda gözükmesini istediğiniz telefon numarasını ve e-posta
                adresini girin.
              </Typography>
              <br />

              {/* TELEFON NUMARASI */}
              <div className={classes.row}>
                <Typography className={classes.title} variant="h4">
                  Telefon numarası
                </Typography>
                <div className={classes.input}>
                  <InputMask
                    mask="0999 999 99 99"
                    disabled={false}
                    maskChar=" "
                    value={editedPhoneNumber}
                    onChange={e => setEditedPhoneNumber(e.target.value)}
                  >
                    {() => (
                      <TextField
                        className={classes.phoneNoField}
                        InputProps={{ className: classes.phoneNoFieldInput }}
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <br />
              <div className={classes.row}>
                <Typography className={classes.title} variant="h4">
                  E-posta
                </Typography>
                <div className={classes.input}>
                  <TextField
                    className={classes.textField}
                    value={editedEmail}
                    onChange={e => setEditedEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className={classes.line} />
              <br />
              <div className={classes.row}>
                <Typography className={classes.title} variant="h4">
                  İlan için not
                </Typography>
                <div className={classes.noteInput}>
                  <TextField
                    className={classes.note}
                    variant="outlined"
                    fullWidth
                    multiline
                    onChange={e => setNote(e.target.value)}
                    value={note}
                    rows={8}
                    placeholder="İlan için not"
                  />
                </div>
              </div>

              <div className={classes.row}>
                <Button className={classes.actionButton} onClick={handleSubmit}>
                  İlanı Oluştur
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateAdModal;
