import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';

import {
  createErrorToast,
  createSuccessToast,
} from '@app-actions/toastActions';
import { updateProfile } from '@app-api/users';
import Spinner from '@app-components/Spinner';
import { selectIsActiveSession } from '@app-redux/selectors/auth';
import { selectCurrentUser, selectCurrentUserId } from '@app-redux/selectors/user';

import useStyles from './styles';

import 'animate.css';

const About = props => {
  const { aboutUser, isOwnProfile, name } = props;

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const isActiveSession = useSelector(selectIsActiveSession);
  const currentUserID = useSelector(selectCurrentUserId);
  const currentUser = useSelector(selectCurrentUser);

  const { userid } = useParams();

  const classes = useStyles(props);

  const {
    mutate: updateUserProfileRequest,
    isLoading: isUpdateProfilePending,
    isError: isUpdateProfileError,
  } = useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['profile', currentUserID]);
      dispatch(
        createSuccessToast({
          message: 'Hikayeniz başarıyla güncellendi',
        }),
      );
    },
    retry: false,
  });

  const [aboutText, setAboutText] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setAboutText(aboutUser);
  }, [aboutUser]);

  const handleEditClick = () => {
    setIsEditing(true);
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    if (aboutText !== aboutUser) {
      updateUserProfileRequest({
        userId: userid,
        newData: { about: aboutText.trim().length ? aboutText : '' },
      });
    }
    setIsEditing(false);
    setIsEditable(false);
  };

  const handleTextChange = e => {
    setAboutText(e.target.value);
  };

  const getPlaceholder = () => {
    if (
      !isActiveSession ||
      (isActiveSession && currentUser.about?.length && !isOwnProfile)
    ) {
      return `${name} henüz hikayesini anlatmadı.`;
    }

    if (isEditing) return '';

    if (isOwnProfile) return 'Hikayeni anlat';

    return `${name} henüz hikayesini anlatmadı.${
      !currentUser.about
        ? ' Görünüşe bakılırsa sen de anlatmamışsın, profiline gidip "Hakkında" kısmını düzenleyebilirsin.'
        : ''
    }`;
  };

  if (isUpdateProfilePending) {
    return <Spinner />;
  }

  if (isUpdateProfileError) {
    dispatch(
      createErrorToast({
        message: 'Hikayeniz güncellenirken bir hata oluştu',
      }),
    );
  }

  return (
    <section
      className={`${classes.aboutSection} animate__animated
      ${isEditing ? 'animate__flash animate__slow' : ''}`}
    >
      <TextField
        className={classes.aboutTextField}
        fullWidth
        multiline
        disabled={!isEditable}
        rows={8}
        onChange={handleTextChange}
        value={aboutText}
        inputRef={input => input && isEditing && input.focus()}
        placeholder={getPlaceholder()}
      />
      <Button
        className={classes.editButton}
        onClick={isEditing ? handleSaveClick : handleEditClick}
      >
        {!isEditing ? 'Düzenle' : 'Kaydet'}
      </Button>
    </section>
  );
};

export default About;
