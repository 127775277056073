import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import {
  Avatar,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { debounce } from 'lodash';

import { getUsers } from '@app-api/users';
import Spinner from '@app-components/Spinner';
import { API_CONSTANTS } from '@app-constants';
import { getShortenedName } from '@app-utils';

import useStyles from './styles';

const SearchUsers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const {
    data: { users },
    isLoading,
  } = useQuery(
    ['searchUsers', debouncedSearchTerm],
    () =>
      getUsers({
        action: API_CONSTANTS.USER.GET_USER_ACTION_TYPES.SEARCH,
        query: debouncedSearchTerm,
      }),
    { initialData: { users: [] } },
  );

  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const isMediumScreen = useMediaQuery('(max-width:1400px)');

  const getPlaceholder = () =>
    isMediumScreen
      ? 'Arama Yap'
      : 'Arama Yap (Herhangi bir meslek veya isim arayabilirsin. örn: Oyuncu, Ahmet Uluçay vb.)';

  const classes = useStyles({ searchMode: !!debouncedSearchTerm });

  const debouncedSetSearchTerm = debounce(
    () => setDebouncedSearchTerm(searchTerm),
    400,
  );

  useEffect(() => {
    debouncedSetSearchTerm();
  }, [debouncedSetSearchTerm, searchTerm]);

  const renderResults = () =>
    users.map(user => (
      <div key={user._id} className={classes.user}>
        <Link to={`/profile/${user._id}`} className={classes.link}>
          <div className={classes.userGrid}>
            <Avatar className={classes.avatar} src={user.pic} alt={user.name} />
            <Typography variant="h4">
              {isSmallScreen ? getShortenedName(user.name) : user.name}
            </Typography>
            <Typography variant="h4">{user.job}</Typography>
          </div>
        </Link>
      </div>
    ));

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section role="search" className={classes.searchSection}>
        <TextField
          className={classes.searchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder={getPlaceholder()}
        />
        {debouncedSearchTerm && (
          <Typography className={classes.title} variant="h3" align="center">
            Kullanıcılar
          </Typography>
        )}
      </section>
      <main className={classes.main}>
        {!debouncedSearchTerm && (
          <Link className={classes.linkToNetwork} to="/network">
            Tanıyor olabileceğim kişileri göster
          </Link>
        )}
        <br />
        <div className={classes.results}>{renderResults()}</div>
      </main>
    </>
  );
};

export default SearchUsers;
