import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import ScrollToTop from '@app-components/ScrollToTop';

import './style.css';
import App from './App';
import Spinner from './components/Spinner';
import { history, persistor, store } from './store';
import theme from './theme';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            <ConnectedRouter history={history}>
              <ScrollToTop />
              <App />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
