import { makeStyles, alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      display: 'none',  
    }
	},
	buttons: {
		backgroundColor: alpha(theme.palette.tertiary.main, 0.15),
		width: '50%',
		padding: theme.spacing(4),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
	},
  button: {
		backgroundColor: theme.palette.tertiary.main,
		margin: theme.spacing(2),
		color: theme.palette.common.white,
    fontSize: theme.typography.h4.fontSize,
		borderRadius: theme.spacing(4),
		padding: `${theme.spacing(2)} auto`,
		fontWeight: 700,
		'&:hover': {
			backgroundColor: theme.palette.tertiary.main,
		},
  },
	title: {
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
		backgroundColor: alpha(theme.palette.tertiary.main, 0.5),
	},
	titleText: {
		verticalAlign: 'center',
		textAlign: 'center',
		width: '70%',
	}
}));

export default useStyles;
