import types from './types';

export const createSuccessToast = ({ message }) => ({
  type: types.SHOW_SUCCESS_TOAST,
  message,
});

export const createErrorToast = ({ message }) => ({
  type: types.SHOW_ERROR_TOAST,
  message,
});

export const createInfoToast = ({ message }) => ({
  type: types.SHOW_INFO_TOAST,
  message,
});

export const createWarningToast = ({ message }) => ({
  type: types.SHOW_WARNING_TOAST,
  message,
});
