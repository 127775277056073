import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Grid, Typography } from '@material-ui/core';

import { createErrorToast } from '@app-actions/toastActions';
import { getAllJobs } from '@app-api/constants';
import { getUsers } from '@app-api/users';
import CrewLogo from '@app-assets/crew.svg';
import Carousel from '@app-components/CustomCarousel';
import Spinner from '@app-components/Spinner';
import { API_CONSTANTS } from '@app-constants';

import useStyles from './styles';

import JobTitles from './components/JobTitles/index';
import SigninForm from './components/SigninForm';

const Signin = () => {
  const dispatch = useDispatch();

  const {
    data: { users },
    isLoading,
    isError,
  } = useQuery(
    'landingPageUsers',
    () =>
      getUsers({
        action: API_CONSTANTS.USER.GET_USER_ACTION_TYPES.LANDING,
      }),
    {
      initialData: { users: [] },
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: { jobTitles },
    isLoading: isJobTitlesLoading,
  } = useQuery('jobTitles', () => getAllJobs(), {
    initialData: { jobTitles: [] },
    refetchOnWindowFocus: false,
  });

  const classes = useStyles();

  if (isError) {
    dispatch(
      createErrorToast({
        message: 'Kullanıcılar alınırken bir hata oldu',
      }),
    );
  }

  return (
    <>
      <Grid container className={classes.heroContainer}>
        <Grid item xs={12} md={6}>
          <SigninForm />
        </Grid>
        <Grid item xs={false} md={6}>
          <img
            className={classes.crewLogo}
            src={CrewLogo}
            alt="Ortak proje için çalışan bir ekip"
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <Grid>
        <Typography className={classes.carouselTitle}>
          Kimler Jungle&apos;da?
        </Typography>
        {isLoading ? <Spinner /> : <Carousel items={users} />}
      </Grid>
      {isJobTitlesLoading ? <Spinner /> : <JobTitles data={jobTitles} />}
      <div className={classes.cta}>
        <Typography className={classes.ctaText}>
          Sen de Jungle&apos;da yerini al!
        </Typography>
        <Link to="/signup">
          <Button className={classes.signupButton}>Kayıt Ol</Button>
        </Link>
      </div>
    </>
  );
};

export default Signin;
