import React from 'react';

import Grid from '@mui/material/Grid';

import UserCard from '@app-components/UserCard';

const renderUserCards = users =>
  users?.map(user => (
    <Grid item xs={5} md={2}>
      <UserCard user={user} small />
    </Grid>
  ));

export const renderPages = pages =>
  pages?.map(page => renderUserCards(page.users));
