import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  line: {
    margin: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(1),
    },
  },
}));

export default useStyles;
