import { makeStyles, lighten } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  aboutSection: {
    width: '90%',
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    borderRadius: '20px',
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    [theme.breakpoints.down('md')]: {
      width: '94%',
      marginLeft: '3%',
    },
  },
  aboutTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: `${theme.typography.h4.fontSize} !important`,
    },
    "& .MuiInputBase-input": {      
      caretShape: 'block',
      caretColor: theme.palette.primary.main,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: `${lighten(theme.palette.common.black, 0.3)} !important`,
      "-webkit-text-fill-color": `${lighten(theme.palette.common.black, 0.3)} !important`,
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiOutlinedInput-input': {
        fontSize: `${theme.typography.body2.fontSize} !important`,
      },
    },
  },
  editButton: props => ({
    display: props.isOwnProfile ? 'inline-block' : 'none',
    fontSize: theme.typography.h6.fontSize,
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '10px',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  }),
}));

export default useStyles;
