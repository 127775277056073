import { toast } from 'react-toastify';

import { all, takeEvery } from 'redux-saga/effects';

const error = ({ error }) => {
  toast.error(error.response.data.message.tr);
};

function* errorSaga() {
  // Take all the actions that end with _FAILED
  yield all([takeEvery(action => /_FAILED$/.test(action.type), error)]);
}

export default errorSaga;
