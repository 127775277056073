import { projectsAxios } from '../axios/projects';

export const createProject = async details => {
  const response = await projectsAxios.post('', JSON.stringify(details), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const { data } = response;

  return data;
};

export const getProject = async projectId => {
  const response = await projectsAxios.get(`/${projectId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  });

  const { data } = response;
  
  return data;
};

export const getAllProjects = async () => {
  const response = await projectsAxios.get('', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  });

  const { data } = response;
  
  return data;
};

export const editProject = async ({ projectId, newData }) => {
  const response = await projectsAxios.put(`/${projectId}`, { project: {...newData, } }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  const { data } = response;

  return data;
};

export const completeProject = async projectId => {
  const response = await projectsAxios.put(`/${projectId}`, { project: { isCompleted: true } }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  const { data } = response;

  return data;
};
