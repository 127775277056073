import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  crewLogo: {
    width: '80%',
    height: '80%',
    display: 'inline-block',
    marginLeft: theme.spacing(8),
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
      marginTop: '-2rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heroContainer: {
    paddingLeft: theme.page.layout.paddingLeft,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: '32px',
      marginTop: theme.spacing(6),
    },
  },
  carouselTitle: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  signupButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(2),
    fontSize: theme.typography.h3.fontSize,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  cta: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginBottom: theme.spacing(8),
    },
  },
  ctaText: {
    display: 'block',
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
}));

export default useStyles;
