import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    left: '0',
    bottom: '0',
    right: '0',
    marginTop: theme.spacing(8),
    height: '20vh',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(16),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
      height: '14vh',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Courier',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  },
  title: {
    fontSize: theme.typography.h2.fontSize,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  motto: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      marginLeft: theme.spacing(4),
      justifySelf: 'flex-end',
    },
  },
  socialMedia: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  socials: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      marginLeft: theme.spacing(3),
      alignSelf: 'center',
      justifyContent: 'end',
    },
  },
  socialLogos: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(0),
    },
  },
}));

export default useStyles;
