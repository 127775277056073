import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Grid from '@mui/material/Grid';

import useStyles from './styles';
import { renderPages } from './utils';

import { useGetUsersYouMayKnowQuery } from './queries';

const UsersYouMayKnow = () => {
  const { ref, inView } = useInView();

  const { data, fetchNextPage, isFetchingNextPage } =
    useGetUsersYouMayKnowQuery();

  const classes = useStyles();

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      <Grid columns={10} className={classes.container} container>
        {renderPages(data)}
      </Grid>
      <div ref={ref} className={classes.anchor} />
    </>
  );
};

export default UsersYouMayKnow;
