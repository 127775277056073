import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dropdown: {
    fontSize: theme.typography.h4.fontSize,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
}));

export const customSelectStyles = {
  container: provided => ({
    ...provided,
    minWidth: '100%',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: '16px',
  }),
  control: () => ({
    display: 'flex',
    borderBottom: '1px solid #003249',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#9e9e9e',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#9e9e9e',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    paddingRight: 8,
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
  }),
  menuList: provided => ({
    ...provided,
    backgroundColor: '#fff',
  }),
  option: provided => ({
    ...provided,
    zIndex: 9999,
  }),
};
