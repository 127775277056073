import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  table: {},
  tableRow: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.95),
    '&:nth-of-type(odd)': {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.75),
    },
  },
  goToAdButton: {
    fontSize: theme.typography.h4.fontSize,
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  category: {
    width: 300,
    [theme.breakpoints.down('md')]: {
      width: 50,
    },
  },
  goToAd: {
    width: 300,
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
  lastDay: {
    width: 300,
    [theme.breakpoints.down('md')]: {
      width: 150,
    },
  },
  noAdsText: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default useStyles;
