import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: '20px',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(220, 220, 220, 0.1)',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginRight: '0',
      padding: '0',
      marginBottom: theme.spacing(4),
    },
  },
  cardHeader: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingTop: '0',
      paddingBottom: '0',
      marginLeft: '0',
      marginRight: '0',
    },
  },
  line: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
  },
  link: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    border: '2px solid red',
  },
  cardHeaderTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: '0',
    },
  },
  userInfoGrid: {
    display: 'grid',
    marginTop: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
  },
  userInfoName: {
    gridColumn: '1 / span 3',
    gridRow: '1 / span 1',
  },
  userInfoJob: {
    gridColumn: '1 / span 3',
    gridRow: '2 / span 1',
  },
  userInfoPic: {
    gridColumn: '4 / span 1',
    gridRow: '2 / span 2',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridRow: '1 / span 2',
      margin: '0',
    },
  },
  userInfoDaysBetween: {
    gridColumn: '1 / span 3',
    gridRow: '3 / span 1',
  },
  actionButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    borderRadius: '1rem',
    border: `1px solid ${theme.palette.secondary.main}`,
    transition: 'all 0.4s',
    fontSize: theme.typography.h6.fontSize,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
