import { get } from 'lodash';
import moment from 'moment';

import { WORD_BREAKS } from './Constants';

/**
 *
 * @param {string} date1 - Date in 'MM/DD/YYYY' format
 * @param {string} date2 - Date in 'MM/DD/YYYY' format
 */
export const calcDaysBetween = (date1, date2) => {
  const dateObjOne = new Date(date1);
  const dateObjTwo = new Date(date2);
  const diffTime = dateObjOne.getTime() - dateObjTwo.getTime();

  return diffTime / (1000 * 3600 * 24);
};

export const getShortenedName = name =>
  name.length < WORD_BREAKS.LARGE_SCREEN
    ? name
    : `${name.slice(0, WORD_BREAKS.LARGE_SCREEN - 3)}...`;

// export const addLineBreak = text => {
//   const words = text.split(' ');
//   console.log('words', words);
//   console.log(words.some(word => word.length >= WORD_BREAKS.MULTIPLE_WORDS));

//   return words.some(word => word.length >= WORD_BREAKS.MULTIPLE_WORDS) ? (
//     <>{words.map(word => <>{ word }</> + <br />)}</>
//   ) : (
//     text
//   );
// };

/**
 *
 * @param {string} createdAt Date in 'MM/DD/YYYY' format
 * @returns how many days ago the specified item was created
 */
export const daysAgo = createdAt => {
  const today = new Date();
  const todaysDate = `${
    today.getMonth() + 1
  }/${today.getDate()}/${today.getFullYear()}`;
  const createdDateElems = createdAt.split('-');
  const createdDate = `${createdDateElems[1]}/${
    createdDateElems[2].split('T')[0]
  }/${createdDateElems[0]}`;

  return calcDaysBetween(todaysDate, createdDate);
};

/**
 *
 * @param {Object} obj
 * @param  {Array: string} path
 * @returns an array consisting of the selected properties
 * of each entry in an object
 */
// e.g getObjPropsAsArray(JOB_TITLES, "plural", "en") ->
// returns English names of plural versions of each job title
export const getObjPropsAsArray = (obj, ...path) =>
  Object.entries(obj).map(entry => get(entry[1], [...path]));

// TODO: Create a common method for creating API calls.

// export const createAPICall = async (fn) => {
//   try {
//     const response = await fn();
//     const { data } = response;

//     return data;
//   } catch (error) {
//     return error?.response?.data;
//   }
// }

export const formatISODate = date => moment(date).format('DD.MM.YYYY');

export const convertValuesToOptions = values =>
  values.map(value => ({ value, label: value }));

export const convertValueToOptions = value => {
  if (!value) {
    return null;
  }

  return { value, label: value };
};
