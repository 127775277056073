import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';

import ProfileSuggestions from '@app-components/ProfileSuggestions';
import TypographyBold from '@app-components/TypographyBold';
import UserCard from '@app-components/UserCard';
import { selectCurrentUser } from '@app-redux/selectors/user';

import useStyles from './styles';

import AdCard from './components/AdCard';

const Ads = () => {
  const currentUser = useSelector(selectCurrentUser);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TypographyBold variant="h2" align="center">
        İlanlar
      </TypographyBold>
      <hr className={classes.line} />
      <Grid container direction="row" spacing={2} padding={2}>
        <Box component={Grid} item md={3} display={{ xs: 'none', md: 'block' }}>
          <UserCard user={currentUser} />
        </Box>
        {/* Projeler */}
        <Grid item xs={12} md={6}>
          <AdCard />
        </Grid>
        {/* Taniyor olabilecegin kisiler */}
        <Box component={Grid} item md={3} display={{ xs: 'none', md: 'block' }}>
          <ProfileSuggestions />
        </Box>
      </Grid>
    </div>
  );
};

export default Ads;
