import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  spinner: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

export default useStyles;
