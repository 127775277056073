import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiInput-underline': {
      '&::after, &::before': {
        display: 'none',
      },
      fontSize: theme.typography.h4.fontSize,

      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
  },
  phoneNoField: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    border: 'none !important',
    outline: 'none',
    '& .MuiInput-input': {
      '&::after, &::before': {
        display: 'none',
      },
    },
    '& > *': {
      '&::after, &::before': {
        display: 'none',
        border: 'none',
      },
    },
  },
  phoneNoFieldInput: {
    fontSize: theme.typography.h4.fontSize,

    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  dateField: {
    fontSize: theme.typography.h4.fontSize,

    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  row: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '4fr 4fr 4fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '5fr 7fr 0fr',
    },
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#000',
    marginTop: theme.spacing(2),
    marginBottom: `${theme.spacing(2)} !important`,
  },
  title: {
    gridColumn: '1 / span 1',
    verticalAlign: 'middle',
    lineHeight: theme.typography.h4.fontSize,
  },
  input: {
    gridColumn: '2 / span 1',
    '& > *': {
      width: '90%',
    },
    [theme.breakpoints.down('md')]: {
      '& > *': {
        width: '100%',
      },
    },
  },
  outer: {
    backgroundColor: theme.palette.tertiary.main,
    padding: theme.spacing(2),
    borderRadius: '20px',
  },
  inner: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(6),
    borderRadius: '20px',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  turkishCurrency: {
    fontSize: theme.typography.h3.fontSize,
    margin: theme.spacing(1),
  },
  note: {
    '& .MuiInputBase-root': {
      fontSize: theme.typography.h4.fontSize,
    },
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
  },
  noteInput: {
    gridColumnStart: '2',
    gridColumnEnd: '4',
  },
  actionButton: {
    gridColumn: '3 / span 1',
    fontSize: theme.typography.h3.fontSize,
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    borderRadius: '10px',
    border: '0.2rem solid transparent',
    marginTop: theme.spacing(2),
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.secondary.main,
      border: `0.2rem solid ${theme.palette.secondary.main}`,
    },
  },
}));

export default useStyles;
