export const WORD_BREAKS = {
  LARGE_SCREEN: 14,
  MULTIPLE_WORDS: 8,
};

export const API_CONSTANTS = {
  CONSTANTS: {
    GET_CONSTANTS_TYPE: {
      JOB_TITLES: 'JOB_TITLES',
      PROJECT_CATEGORIES: 'PROJECT_CATEGORIES',
      CITIES: 'CITIES',
    },
  },
  USER: {
    GET_USER_ACTION_TYPES: {
      LANDING: 'LANDING',
      YOU_MAY_KNOW: 'YOU_MAY_KNOW',
      SEARCH: 'SEARCH',
    },
    GET_USER_CONFIG: {
      INITIAL_LENGTH: 50,
      USERS_PER_PAGE: 25,
    },
  },
};
