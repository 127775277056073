import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Routing from './Routing';

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <ToastContainer
        className="toast-container"
        position="top-right"
        autoClose={2000}
      />
      <Navbar />
      <QueryClientProvider client={queryClient}>
        <Routing />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <Footer />
    </>
  );
}

export default App;
