import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    width: '80%',
    marginLeft: '10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: theme.spacing(4),
    '& > *': {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginLeft: '5%',
      marginTop: theme.spacing(6),
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  },
  userNameLink: {
    textTransform: 'none',
    color: theme.palette.common.black,
  },
  userInfoLabel: {
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  userAvatar: {
    [theme.breakpoints.down('md')]: {
      width: '20px',
      height: '20px',
      marginLeft: theme.spacing(2),
    },
  },
  adInfo: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '1fr 3fr',
    gridTemplateAreas: `
    ". category ."
    "projectInfo . userInfo"`,
    backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      paddingBottom: '0 !important',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateRows: '0.5fr 2.5fr',
      gridTemplateAreas: `
      "category category category category"
      "projectInfo projectInfo userInfo userInfo"`,
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '6px',
        '& > *(:last-child)': {
          margin: '4px !important',
        },
        '&:first-of-type': {
          justifyContent: 'space-between',
        },
      },
    },
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#000',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: '1px',
      marginBottom: theme.spacing(1),
      width: '80%',
    },
  },
  founderInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfo: {
    gridArea: 'userInfo',
    display: 'flex',
    justifyContent: 'start',
    [theme.breakpoints.down('md')]: {
      marginTop: '4px',
      '& > *': {
        margin: '0',
        padding: '0',
      },
    },
  },
  projectInfo: {
    gridArea: 'projectInfo',
    [theme.breakpoints.down('md')]: {
      marginTop: '12px',
      '&:last-child': {
        marginTop: '12px',
      },
    },
  },
  categoryHeader: {
    gridArea: 'category',
  },
  generalInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridTemplateRows: '1fr 3fr 4fr',
    gridTemplateAreas: `
    "adInfoHeader noteTitle"
    "adCard note"
    "projectCard note"
    `,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  },
  adCard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: lighten(theme.palette.secondary.main, 0.8),
    borderRadius: '20px',
    padding: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  adInfoHeader: {
    gridArea: 'adInfoHeader',
    paddingBottom: theme.spacing(1),
  },
  projectInfoHeader: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  adCardGrid: {
    gridArea: 'adCard',
  },
  projectCard: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.8),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    padding: theme.spacing(2),
    '& > *': {
      // marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  projectCardGrid: {
    gridArea: 'projectCard',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  notes: {
    gridArea: 'note',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: '0',
      marginTop: theme.spacing(1),
    },
  },
  noteTitle: {
    gridArea: 'noteTitle',
  },
  notesField: {
    borderRadius: '20px',
    '& .MuiInputBase-root': {
      fontSize: theme.typography.h4.fontSize,
    },
    '& .MuiInput-underline::before': {
      display: 'none',
    },
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    padding: theme.spacing(2),
    width: '100%',
    '& .MuiInputBase-input': {
      color: '#444',
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiInputBase-root': {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
  deleteAdButton: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.secondary.main,
    backgroundColor: '#fff',
    alignSelf: 'end',
    border: `1px solid ${theme.palette.secondary.main}`,
    textTransform: 'none',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  inspectProjectButton: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.main, 0.8),
    alignSelf: 'flex-end !important',
    border: `1px solid ${theme.palette.secondary.main}`,
    textTransform: 'none',
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
  },
  inspectProjectLinkMobile: {
    fontSize: theme.typography.body2.fontSize,
    fontFamily: 'Roboto',
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.main}`,
    alignSelf: 'end',
    borderRadius: '10px',
    textAlign: 'center',
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  inspectProjectLink: {
    width: isLargeScreen => (isLargeScreen ? '60%' : '80%'),
    alignSelf: 'end',
  },
}));

export default useStyles;
