import { useInfiniteQuery } from 'react-query';

import { getUsers } from '@app-api/users';
import { API_CONSTANTS } from '@app-constants';

const {
  USER: { GET_USER_ACTION_TYPES, GET_USER_CONFIG },
} = API_CONSTANTS;

export const useGetUsersYouMayKnowQuery = () => {
  const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    'usersYouMayKnow',
    ({ pageParam = 1 }) =>
      getUsers({
        action: GET_USER_ACTION_TYPES.YOU_MAY_KNOW,
        pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages =
          Math.ceil(
            (lastPage.totalUsers - GET_USER_CONFIG.INITIAL_LENGTH) /
              GET_USER_CONFIG.USERS_PER_PAGE,
          ) + 1;
        const nextPage = allPages.length + 1;

        return nextPage <= maxPages ? nextPage : undefined;
      },
    },
  );

  return { data: data?.pages, fetchNextPage, isFetchingNextPage };
};
