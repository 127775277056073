import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Card, Typography } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import TypographyBold from '@app-components/TypographyBold';

import useStyles from './styles';

const Projects = ({ data }) => {
  const classes = useStyles();

  const jobs = new Set(); // unique jobs

  data.forEach(project => {
    project.creatorsJobs.forEach(job => {
      jobs.add(job);
    });
  });

  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    if (data.length) {
      setExpanded(data[0].createdBy.job);
    }
  }, [data]);

  const jobsData = {};

  jobs.forEach(job => {
    const filteredProjects = data.filter(project =>
      project.creatorsJobs.includes(job),
    );
    jobsData[job] = filteredProjects;
  });

  const toggleExpanded = job => {
    if (expanded === job) {
      setExpanded(null);
    } else {
      setExpanded(job);
    }
  };

  const renderProject = (project, darkened) => (
    <Link to={`/project/${project._id}`} key={project._id}>
      <Card
        className={`${classes.projectCard} ${
          darkened ? `${classes.dark}` : `${classes.light}`
        }`}
      >
        <div>
          <TypographyBold variant="h5" align="left">
            {project.title}
          </TypographyBold>
          <Typography variant="h5" align="left">
            {project.category}
          </Typography>
        </div>
        <div>
          <Typography variant="h6" align="right">
            {new Date(project.createdAt).toLocaleDateString('tr-TR')}
          </Typography>
          <Button className={classes.goToProjectButton}>Projeyi incele</Button>
        </div>
      </Card>
    </Link>
  );

  const renderJobTitles = () =>
    Object.entries(jobsData).map(([job, projects]) => (
      <Accordion
        expanded={expanded === job}
        key={job}
        className={classes.accordion}
        onClick={() => toggleExpanded(job)}
      >
        <AccordionSummary>
          <Button
            onClick={() => toggleExpanded(job)}
            className={classes.jobButton}
          >
            {job}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          {projects.map((project, index) =>
            renderProject(project, !(index % 2)),
          )}
        </AccordionDetails>
      </Accordion>
    ));

  return <>{renderJobTitles()}</>;
};

export default Projects;
