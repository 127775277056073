import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkIcon from '@mui/icons-material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { createInfoToast } from '@app-actions/toastActions';

import useStyles from './styles';

const SocialShareLinks = ({ type, id }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const projectURL = `https://www.jungleonweb.com/project/${id}`;
  const adURL = `https://www.jungleonweb.com/ads/${id}`;

  const urls = useMemo(
    () => ({
      project: {
        twitter: `https://twitter.com/share?url=${projectURL}&text=Bu projeye göz at!&hashtags=jungle,setteyerinial&via=jungleonweb`,
        facebook: `https://www.facebook.com/dialog/share?app_id=1062649364462796&href=${projectURL}&quote=Bu projeye göz at!&hashtag=jungleonweb`,
        whatsapp: `https://wa.me/?text=Bu projeye göz at! ${projectURL}&action=share/whatsapp/share`,
      },
      ad: {
        twitter: `https://twitter.com/share?url=${adURL}&text=Bu ilana göz at!&hashtags=jungle,setteyerinial&via=jungleonweb`,
        facebook: `https://www.facebook.com/dialog/share?app_id=1062649364462796&href=${adURL}&quote=Bu ilana göz at!&hashtag=jungleonweb`,
        whatsapp: `https://wa.me/?text=Bu ilana göz at! ${adURL}&action=share/whatsapp/share`,
      },
    }),
    [adURL, projectURL],
  );

  const openSocialShareLink = useCallback(
    platform => window.open(urls[type][platform], '_blank'),
    [type, urls],
  );

  const copyLinkToClipboard = () => {
    window.navigator.clipboard.writeText(
      type === 'project' ? projectURL : adURL,
    );
    dispatch(createInfoToast({ message: 'Bağlantı kopyalandı' }));
  };

  return (
    <Box className={classes.iconContainer}>
      <IconButton
        onClick={() => openSocialShareLink('facebook')}
        className={classes.shareIconButton}
      >
        <FacebookIcon className={classes.socialIcon} />
      </IconButton>
      <IconButton
        onClick={() => openSocialShareLink('twitter')}
        className={classes.shareIconButton}
      >
        <TwitterIcon className={classes.socialIcon} />
      </IconButton>
      <IconButton
        onClick={() => openSocialShareLink('whatsapp')}
        className={classes.shareIconButton}
      >
        <WhatsAppIcon className={classes.socialIcon} />
      </IconButton>
      <IconButton
        onClick={copyLinkToClipboard}
        className={classes.shareIconButton}
      >
        <LinkIcon className={classes.socialIcon} />
      </IconButton>
    </Box>
  );
};

export default SocialShareLinks;
