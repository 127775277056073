import React from 'react';

import { Card } from '@material-ui/core';

import useStyles from './styles';

import SignupForm from '../SignupForm';

const Signupcard = () => {
  const classes = useStyles();

  return (
    <Card raised className={classes.signupCard}>
      <SignupForm />
    </Card>
  );
};

export default Signupcard;
