import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { createErrorToast } from '@app-actions/toastActions';
import { setUser } from '@app-actions/userActions';
import { getUserProfile } from '@app-api/users';
import Spinner from '@app-components/Spinner';
import { selectCurrentUserId, selectIsUserPending } from '@app-selectors/user';

import MainPanel from './components/mainPanel';
import TopPanel from './components/topPanel';

const Profile = () => {
  const dispatch = useDispatch();

  const currentUserID = useSelector(selectCurrentUserId);
  const isReduxPending = useSelector(selectIsUserPending);

  const { userid } = useParams();

  const isOwnProfile = currentUserID === userid;

  const { isLoading, isError, data } = useQuery(
    ['profile', userid],
    () => getUserProfile({ userId: userid }),
    {
      onSuccess: data => {
        const { user } = data;

        if (isOwnProfile) {
          dispatch(setUser(user));
        }
      },
    },
  );

  const isPending = isReduxPending || isLoading;

  if (isPending) {
    return <Spinner />;
  }

  if (isError) {
    dispatch(
      createErrorToast({
        message: 'Profil görüntülenirken hata oluştu.',
      }),
    );
  }

  const { user, projects } = data;
  const { name, about } = user;

  return (
    <>
      <TopPanel user={user} isOwnProfile={isOwnProfile} />
      <MainPanel
        about={about}
        name={name}
        isOwnProfile={isOwnProfile}
        projects={projects}
      />
    </>
  );
};

export default Profile;
