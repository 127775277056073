import { constantsAxios } from '../axios/constants';
import { API_CONSTANTS } from '@app-constants';

export const searchCities = async ({ query }) => {
  const response = await constantsAxios.get('', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    params: { query, type: API_CONSTANTS.CONSTANTS.GET_CONSTANTS_TYPE.CITIES }
  });

  const { data } = response;
  
  return data;
}

export const getProjectCategories = async () => {
  const response = await constantsAxios.get('', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    params: { type: API_CONSTANTS.CONSTANTS.GET_CONSTANTS_TYPE.PROJECT_CATEGORIES }
  });

  const { data } = response;
  
  return data;
}

export const searchJobs = async ({ query }) => {
  const response = await constantsAxios.get(``, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    params: { query, type: API_CONSTANTS.CONSTANTS.GET_CONSTANTS_TYPE.JOB_TITLES }
  });

  const { data } = response;
  
  return data;
}

export const getAllJobs = async () => {
  const response = await constantsAxios.get(``, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    params: { type: API_CONSTANTS.CONSTANTS.GET_CONSTANTS_TYPE.JOB_TITLES, getAll: true }
  });

  const { data } = response;
  
  return data;
}
