import axios from '../axios/signin';

export const sendSigninRequest = async ({ email, password }) => {
  const response = await axios.post(
    '',
    { email, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const { data } = response;

  return data;
};
