import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import useStyles from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div className={classes.infoContainer}>
        <div className={classes.title}>Jungle</div>
        <div className={classes.motto}>Hikayeni Anlat</div>
      </div>
      <div className={classes.socials}>
        <span className={classes.socialMedia}>Sosyal Medya</span>
        <div className={classes.socialLogos}>
          <a
            href="https://www.facebook.com/Jungle-Hikayeni-Anlat-207185301345185/?hc_ref=ARQGNH5L5JwH7OO74y22LX1todwi_nnZRw1007Bf7BoM087hMwtC0YRbVC4wroN0wjE&fref=nf&__tn__=kC-R"
            rel="noreferrer"
            target="_blank"
          >
            <FacebookIcon fontSize="large" />
          </a>
          <a
            href="https://twitter.com/jungleonweb"
            rel="noreferrer"
            target="_blank"
          >
            <TwitterIcon fontSize="large" />
          </a>
          <a
            href="https://www.instagram.com/jungleonweb/"
            rel="noreferrer"
            target="_blank"
          >
            <InstagramIcon fontSize="large" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
