import { adsAxios } from '../axios/ads';

export const createAd = async ({ details }) => {
  const response = await adsAxios.post('', JSON.stringify(details), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  const { data } = response;
  
  return data;
}

export const getAd = async ({ adId }) => {
  const response = await adsAxios.get(`/${adId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  const { data } = response;
  
  return data;
}

export const deleteAd = async ({ adId }) => {
  const response = await adsAxios.delete(`/${adId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  const { data } = response;
  
  return data;
}

export const getAllAds = async () => {
  const response = await adsAxios.get(``, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  const { data } = response;
  
  return data;
}
