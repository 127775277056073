import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { IconButton } from '@mui/material';

import { logout } from '@app-actions/authActions';
import { getUsers } from '@app-api/users';
import { API_CONSTANTS } from '@app-constants';
import { selectCurrentUserId } from '@app-selectors/user';

import useStyles from './styles';
// import onClickOutside from 'react-onclickoutside'

const Navbar = () => {
  const dispatch = useDispatch();
  const currentUserID = useSelector(selectCurrentUserId);

  // we need search users effect to not run on initial render by default
  const firstUpdate = useRef(true);

  const [userResults, setUserResults] = useState([]);
  const [searchTerm] = useState('');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const isMenuOpen = Boolean(menuAnchor);
  const isSmallScreen = useMediaQuery('(max-width:1880px)');

  const classes = useStyles(isSmallScreen);

  const handleSignout = () => {
    dispatch(logout());
  };

  const handleSearchUsers = async term => {
    const searchData = await getUsers({
      action: API_CONSTANTS.USER.GET_USER_ACTION_TYPES.SEARCH,
      query: term,
    });
    const { users } = searchData;
    setUserResults(users);
  };

  const handleHamburgerClick = e => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  // Navbar.handleClickOutside = () => setShowResults(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }
    handleSearchUsers(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const renderResults = () =>
    userResults?.map(user => (
      <div className={classes.user} key={user._id} id="user-results">
        <Link to={`/profile/${user._id}`} className={classes.link}>
          <div className={classes.userGrid}>
            <Avatar
              src={user.pic}
              alt={user.name}
              className={classes.userAvatar}
            />
            <Typography variant="h5">{user.name}</Typography>
            <Typography variant="h5">{user.job}</Typography>
          </div>
        </Link>
      </div>
    ));

  const HamburgerMenu = (
    <Box
      className={classes.hamburgerMenuContainer}
      display={{ xs: 'block', md: 'none' }}
    >
      <IconButton id="hamburger-button" onClick={handleHamburgerClick}>
        <MoreVertIcon color="primary" fontSize="medium" />
      </IconButton>
      <Menu
        id="hamburger-menu"
        anchorEl={menuAnchor}
        open={isMenuOpen}
        onClose={handleMenuClose}
        className={classes.hamburgerMenu}
      >
        <MenuItem key="createproject">
          <Link to="/createproject">
            <Button
              className={classes.menuButton}
              color="inherit"
              variant="text"
            >
              Proje Başlat
            </Button>
          </Link>
        </MenuItem>

        <MenuItem key="profile">
          <Link to={`/profile/${currentUserID}`}>
            <Button
              className={classes.menuButton}
              color="inherit"
              variant="text"
            >
              Profil
            </Button>
          </Link>
        </MenuItem>

        <MenuItem key="signout">
          <Button
            className={classes.menuButton}
            color="inherit"
            variant="text"
            onClick={handleSignout}
          >
            Çıkış Yap
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  );

  return (
    <nav>
      <AppBar className={classes.appbar} position="fixed">
        <Grid container>
          <Grid item xs={2}>
            <Link
              className={classes.titleContainer}
              to={currentUserID ? '/network' : '/signin'}
            >
              <Typography variant="h3" className={classes.title} align="center">
                Jungle
              </Typography>
            </Link>
          </Grid>

          {/* <OutlinedInput
            //   startAdornment={
            //     <InputAdornment position="start">
            //       <IconButton>
            //         <SearchRoundedIcon className={classes.searchIcon} />
            //       </IconButton>
            //     </InputAdornment>
            //   }
            //   inputProps={{ style: { color: '#fff', border: 'none',
            //  margin: 'none', padding: 'none' } }}
            //   className={classes.search}
            //   placeholder="Arama yap"
            //   onChange={(e) => setSearchTerm(e.target.value)}
            //   value={searchTerm}
            // />
            */}

          {currentUserID ? (
            <>
              <Box
                component={Grid}
                item
                xs={4}
                sm={3}
                md={2}
                align="right"
                className={classes.searchbar}
              >
                <Link to="/searchusers">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    <SearchRoundedIcon
                      fontSize="medium"
                      className={classes.searchIcon}
                    />
                    <span className={classes.searchText}>Arama Yap</span>
                  </Button>
                </Link>
              </Box>
              <Box
                component={Grid}
                item
                align="right"
                xs={6}
                md={7}
                display={{ xs: 'none', md: 'inline-block' }}
              >
                <Link to="/projects">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    Projeler
                  </Button>
                </Link>
                <Link to="/ads">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    İlanlar
                  </Button>
                </Link>
                <Link to="/createproject">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    Proje Başlat
                  </Button>
                </Link>
                <Link to={`/profile/${currentUserID}`}>
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    Profil
                  </Button>
                </Link>
                <Button
                  className={classes.menuButton}
                  color="inherit"
                  variant="text"
                  onClick={handleSignout}
                >
                  Çıkış Yap
                </Button>
              </Box>
              <Box
                component={Grid}
                item
                xs={6}
                align="right"
                display={{ xs: 'inline-block', md: 'none' }}
              >
                <Link to="/projects">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    Projeler
                  </Button>
                </Link>
                <Link to="/ads">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    İlanlar
                  </Button>
                </Link>
                {HamburgerMenu}
              </Box>
            </>
          ) : (
            <>
              <Grid item xs={3} />
              <Grid item xs={6} align="right" className={classes.authButtons}>
                <Link to="/signin">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    Giriş Yap
                  </Button>
                </Link>
                <Link to="/signup">
                  <Button
                    className={classes.menuButton}
                    color="inherit"
                    variant="text"
                  >
                    Kayıt Ol
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={1} />
            </>
          )}
        </Grid>
      </AppBar>
    </nav>
  );
};

// const clickOutsideConfig = {
//   handleClickOutside: (instance) => instance.handleClickOutside
// };

export default Navbar;
