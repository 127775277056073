import { get } from 'lodash';
import { createSelector } from 'reselect';

export const selectIsActiveSession = createSelector(
  state => get(state, ['auth', 'isActiveSession']),
  isActiveSession => isActiveSession,
);

export const selectRedirectPath = createSelector(
  state => get(state, ['auth', 'redirectPath']),
  redirectPath => redirectPath,
);

export const selectIsAuthPending = createSelector(
  state => get(state, ['auth', 'isPending']),
  isPending => isPending,
);
