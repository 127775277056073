import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  projectCard: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  projectsTitle: {
    textDecoration: 'underline',
  },
  light: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.95),
  },
  dark: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.85),
  },
  jobButton: {
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textTransform: 'none',
    borderRadius: theme.spacing(2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.4),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  accordion: {
    width: '80%',
    marginTop: theme.spacing(2),
    marginLeft: '10%',
    boxShadow: '2px 2px 10px 1px rgba(0, 0, 0, 0.6) !important',
    borderRadius: `20px !important`,
    '&::before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      marginLeft: '10% !important',
    },
  },
  goToProjectButton: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.h6.fontSize,
    textTransform: 'none',
    borderRadius: theme.spacing(2),
    transition: 'all 0.4s',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
    },
    '&:hover': {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.4),
    },
  },
}));

export default useStyles;
