import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar, Button, Typography } from '@material-ui/core';

import { selectIsActiveSession } from '@app-selectors/auth';

import useStyles from './styles';

import ContactModal from './modals/ContactModal';
import { EditModal } from './modals/EditModal';
import ProfileCompletion from './profileCompletion';

const TopPanel = props => {
  const isActiveSession = useSelector(selectIsActiveSession);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const { user, isOwnProfile } = props;

  const classes = useStyles(props);

  const renderEditModal = (
    <EditModal open={showEditModal} handleOpen={setShowEditModal} />
  );

  const showProfileCompletion =
    !user?.location ||
    user?.pic === process.env.REACT_APP_DEFAULT_PIC_URI ||
    !user?.job ||
    !user?.about;

  const renderContactModal = (
    <ContactModal
      open={showContactModal}
      handleOpen={setShowContactModal}
      user={user}
    />
  );

  return (
    <>
      <header className={classes.container}>
        <div className={classes.userInfoContainer}>
          <Avatar
            className={classes.avatar}
            src={user?.pic}
            alt="user's picture"
          />
          <div className={classes.userInfo}>
            <Typography variant="h3">{user.name}</Typography>
            <Typography variant="h4">{user.job}</Typography>
            {!isActiveSession ? (
              <Typography variant="h5" className={classes.noContact}>
                İletişim bilgilerini görebilmek için lütfen giriş yapın
              </Typography>
            ) : (
              <div className={classes.contactContainer}>
                {user.location ? (
                  <Typography variant="h5">
                    {user.location}&nbsp;-&nbsp;
                  </Typography>
                ) : (
                  ''
                )}
                <Typography
                  variant="h5"
                  className={classes.contactButton}
                  onClick={() => setShowContactModal(true)}
                >
                  İletişim Bilgileri
                </Typography>
              </div>
            )}
          </div>
        </div>
        <Button
          className={classes.editButton}
          onClick={() => setShowEditModal(true)}
        >
          Profilini Düzenle
        </Button>
      </header>
      <hr className={classes.line} />
      {isOwnProfile && showProfileCompletion && (
        <ProfileCompletion
          location={user.location}
          profilePic={user.pic}
          primaryJob={user.job}
          about={user.about}
        />
      )}
      {isOwnProfile && renderEditModal}
      {renderContactModal}
    </>
  );
};

export default TopPanel;
