import { get } from 'lodash';
import { createSelector } from 'reselect';

export const selectCurrentUser = createSelector(
  state => get(state, ['user', 'data']),
  user => user,
);

export const selectCurrentUserId = createSelector(
  state => get(state, ['user', 'data', '_id']),
  userId => userId,
);

export const selectIsUserPending = createSelector(
  state => get(state, ['user', 'isPending']),
  isPending => isPending,
);
