import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '@app-redux/sagas';

import createRootReducer from '../redux/reducers';

const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history),
);

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(history), sagaMiddleware),
);

const createPersistedStore = () => {
  const store = createStore(persistedReducer, {}, enhancer);

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor, history };
};

export default createPersistedStore;
