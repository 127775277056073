import types from '../actions/types';

const INITIAL_STATE = {
  data: {
    about: '',
    email: '',
    hidePnumber: false,
    job: '',
    location: '',
    name: '',
    pic: '',
    pnumber: '',
    _id: '',
  },
  isPending: false,
  error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
    case types.SET_USER: {
      const { userData } = action;

      return {
        ...state,
        data: userData,
      };
    }
    case types.UPDATE_USER_REQUEST: {
      return {
        ...state,
        isPending: true,
      };
    }
    case types.UPDATE_USER_SUCCEEDED: {
      return {
        ...state,
        isPending: false,
      };
    }
    case types.UPDATE_USER_FAILED: {
      return {
        ...state,
        isPending: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
