import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  anchor: {
    bottom: '0',
  },
  container: {
    marginTop: theme.spacing(12),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > *': {
      height: '100% !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

export default useStyles;
