import types from './types';

export const checkSessionRequest = () => ({
  type: types.CHECK_SESSION_REQUEST,
});

export const signinRequest = ({ email, password }) => ({
  type: types.SIGNIN_REQUEST,
  email,
  password,
});

export const signinSuccess = userData => ({
  type: types.SIGNIN_SUCCEEDED,
  userData,
});

export const signinFail = error => ({
  type: types.SIGNIN_FAILED,
  error,
});

export const signupRequest = userData => ({
  type: types.SIGNUP_REQUEST,
  userData,
});

export const signupSuccess = () => ({
  type: types.SIGNUP_SUCCEEDED,
});

export const signupFail = error => ({
  type: types.SIGNIN_FAILED,
  error,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export default {
  checkSessionRequest,
  signinRequest,
  signinFail,
  signinSuccess,
  signupRequest,
  signupFail,
  signupSuccess,
  logout,
};
