import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  completionBox: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '80%',
    marginLeft: '10%',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
  },
  completionItem: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  emptyDot: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.5),
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      width: '16px',
      height: '16px',
    },
  },
  filledDot: {
    backgroundColor: theme.palette.secondary.main,
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      width: '16px',
      height: '16px',
    },
  },
}));

export default useStyles;
