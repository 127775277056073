import { makeStyles, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: 0,
    padding: '0',
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
  formTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h2.fontSize,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.h4.fontSize,
    }
  },
  titleBreak: {
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'none',
    },
  },
  formField: {
    width: '95%',
    marginBottom: theme.spacing(1),
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&:after': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      }
    },
    '&:hover': {
      '& .MuiInput-underline': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  formInput: {
    fontSize: theme.typography.h4.fontSize,
    '& -webkit-autofill::first-line': {
      fontSize: theme.typography.h4.fontSize,
    },
    '&::placeholder': {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
      '& -webkit-autofill::first-line': {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
  formElement: {
    minWidth: '70%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    '&:nth-child(3)': {
      marginBottom: theme.spacing(0),
    },
    '&:nth-child(4)': {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '80%',
    },
  },
  formError: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  formErrorIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
    fontSize: theme.typography.h4.fontSize,
  },
  forgotPassword: {
    fontSize: theme.typography.body1.fontSize,
    cursor: 'pointer',
    alignSelf: 'end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: '5%',
    display: 'inline-block',
  },
  formSubmit: {
    alignSelf: 'left',
    width: '70%',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    fontSize: theme.typography.h2.fontSize,
    borderRadius: '20px',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary.main}`,
    transition: "all 0.4s",
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.main, 0.2),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h4.fontSize,
      width: '80%',
    },
  },
  noAccount: {
    color: theme.palette.secondary.main,
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  },
}));

export default useStyles;
