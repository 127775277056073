import types from '../actions/types';

const INITIAL_STATE = {
  isPending: false,
  error: null,
  signupSuccess: false, // will be used to redirect to signin after signup
  isActiveSession: false, // used to determine whether a user is guest or not
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
    case types.CHECK_SESSION_REQUEST: {
      return {
        ...state,
        isPending: true,
      };
    }
    case types.SIGNIN_REQUEST: {
      return {
        ...state,
        isPending: true,
      };
    }
    case types.SIGNIN_SUCCEEDED: {
      return {
        ...state,
        isPending: false,
        isActiveSession: true,
      };
    }
    case types.SIGNIN_FAILED: {
      const { payload } = action;

      return {
        ...state,
        isPending: false,
        error: payload,
      };
    }
    case types.LOGOUT: {
      return {
        ...state,
        isActiveSession: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
