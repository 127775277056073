import { makeStyles, lighten } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formContainer: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
      width: '250px',
    },
  },
  formField: {
    fontSize: `${theme.typography.h4.fontSize} !important`,
    width: '95%',
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&:after': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
    },
    '&:hover': {
      '& .MuiInput-underline': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
    },
    '&::placeholder': {
      fontSize: theme.typography.h4.fontSize,
      color: '#9e9e9e',
      [theme.breakpoints.down('sm')]: {
        fontSize: `${theme.typography.body1.fontSize} !important`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `${theme.typography.body1.fontSize} !important`,
    },
  },
  formInput: {
    fontSize: theme.typography.h4.fontSize,
    '&::placeholder': {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
      '&::placeholder': {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
  inputMask: {
    border: 'none',
  },
  formError: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  formElement: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0),
      padding: theme.spacing(2),
    },
  },
  submitButton: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    fontSize: theme.typography.h3.fontSize,
    borderRadius: '20px',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary.main}`,
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.2),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  jobFormControl: {
    width: '100%',
  },
  hasAlready: {
    fontSize: theme.typography.h4.fontSize,
    textDecoration: 'none',
    maxWidth: '100%',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    display: 'inline-block',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  linkToSignin: {
    textDecoration: 'none',
    textAlign: 'center',
  },
}));

export default useStyles;
