import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'start',
    padding: '0',
    marginLeft: '-4px !important',
  },
  socialIcon: {
    color: theme.palette.primary.main,
    fontSize: `${theme.typography.h3.fontSize} !important`,

    [theme.breakpoints.down('md')]: {
      fontSize: `${theme.typography.h5.fontSize} !important`,
    },
  },
  shareIconButton: {
    padding: theme.spacing(1),
  },
}));

export default useStyles;
