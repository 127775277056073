import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';

import { createErrorToast } from '@app-actions/toastActions';
import { getAllAds } from '@app-api/ads';
import Spinner from '@app-components/Spinner';
import TypographyBold from '@app-components/TypographyBold';
import { calcDaysBetween } from '@app-utils';

import useStyles from './styles';

const AdCard = () => {
  const dispatch = useDispatch();

  const { data: { ads }, isLoading, isError } = useQuery('ads', getAllAds, {
    initialData: { ads: [] },
  });

  const classes = useStyles();

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    dispatch(
      createErrorToast({
        message: 'İlanlar gösterilirken hata oluştu.',
      }),
    );
  }

  const renderUserInfo = item => {
    // WARNING: DO NOT TOUCH
    const today = new Date();
    const todaysDate = `${
      today.getMonth() + 1
    }/${today.getDate()}/${today.getFullYear()}`;
    const createdDateElems = item.createdAt.split('-');
    const createdDate = `${createdDateElems[1]}/${
      createdDateElems[2].split('T')[0]
    }/${createdDateElems[0]}`;
    const daysBetween = calcDaysBetween(todaysDate, createdDate);

    return (
      <div className={classes.userInfoContainer}>
        <Link
          className={classes.userInfoPic}
          to={`/profile/${item.postedBy._id}`}
        >
          <Avatar src={item.postedBy.pic} />
        </Link>
        <div className={classes.userInfo}>
          <Typography variant="h5">{item?.postedBy?.name}</Typography>
          <Typography variant="h6">{item?.postedBy?.job}</Typography>
        </div>
        <Typography variant="h6" className={classes.userInfoDaysBetween}>
          {daysBetween > 1
            ? `${daysBetween} gün önce`
            : daysBetween === 1
            ? 'Dün'
            : 'Bugün'}
        </Typography>
      </div>
    );
  };

  const renderCards = () => {
    if (ads) {
      return ads?.map(item => (
        <Card className={classes.card} key={item._id} raised>
          <div className={classes.cardHeader}>
            <div className={classes.cardTitle}>
              <TypographyBold variant="h3" align="center">
                {item.category} Aranıyor
              </TypographyBold>
            </div>
            <div className={classes.cardSubtitles}>
              <Typography variant="h4">{item?.postedOn?.category}</Typography>
              <Typography variant="h4">{item?.postedOn?.title}</Typography>
            </div>
          </div>
          <hr />
          <CardContent>{renderUserInfo(item)}</CardContent>
          <hr />
          <CardActions className={classes.cardActions}>
            <Link to={`/project/${item?.postedOn?._id}`}>
              <Button className={classes.actionButton}>Projeyi İncele</Button>
            </Link>
            <Link to={`/ads/${item._id}`}>
              <Button className={classes.actionButton}>İlanı İncele</Button>
            </Link>
          </CardActions>
        </Card>
      ));
    }

    return null;
  };

  return <div>{renderCards()}</div>;
};

export default AdCard;
