import React from 'react';
import InputMask from 'react-input-mask';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, TextField } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { push } from 'connected-react-router';
import { useFormik } from 'formik';

import {
  createErrorToast,
  createSuccessToast,
} from '@app-actions/toastActions';
import { sendSignupRequest } from '@app-api/signup';
import Spinner from '@app-components/Spinner';

import useStyles from './styles';
import SignupSchema from './validationSchema';

const SignupForm = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { mutate: signupRequest, isLoading } = useMutation(sendSignupRequest, {
    onSuccess: () => {
      dispatch(
        createSuccessToast({
          message: 'Profil başarıyla oluşturuldu',
        }),
      );
      dispatch(push('/signin'));
    },
    onError: error => {
      dispatch(
        createErrorToast({
          message: error.response.data.message.tr,
        }),
      );
    },
    retry: false,
  });

  const handleSubmit = values => {
    const formValues = values;
    signupRequest(formValues);
  };

  const formik = useFormik({
    initialValues: { name: '', email: '', pnumber: '', password: '' },
    validationSchema: SignupSchema,
    onSubmit: handleSubmit,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
      <div className={classes.formElement}>
        <TextField
          className={classes.formField}
          InputProps={{
            classes: {
              input: classes.formInput,
            },
          }}
          id="new-user-name"
          aria-describedby="new user name"
          placeholder="İsim ve Soyisim"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <div className={classes.formError}>
          {formik.errors.name && formik.touched.name && (
            <>
              <ErrorRoundedIcon className={classes.formErrorIcon} />
              {formik.errors.name}
            </>
          )}
        </div>
      </div>
      <div className={classes.formElement}>
        <TextField
          className={classes.formField}
          InputProps={{
            classes: {
              input: classes.formInput,
            },
          }}
          id="new-user-email"
          aria-describedby="new user email"
          placeholder="E-posta"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <div className={classes.formError}>
          {formik.errors.email && formik.touched.email && (
            <>
              <ErrorRoundedIcon className={classes.formErrorIcon} />
              {formik.errors.email}
            </>
          )}
        </div>
      </div>
      <div className={classes.formElement}>
        <InputMask
          className={classes.inputMask}
          mask="0999 999 99 99"
          disabled={false}
          maskChar=" "
          value={formik.values.pnumber}
          onChange={e => formik.setFieldValue('pnumber', e.target.value)}
        >
          {() => (
            <TextField
              className={classes.formField}
              InputProps={{
                classes: {
                  input: classes.formInput,
                },
              }}
              id="new-user-pnumber"
              aria-describedby="new user phone number"
              value={formik.values.pnumber}
              placeholder="Telefon Numarası(İsteğe Bağlı)"
            />
          )}
        </InputMask>
      </div>
      <div className={classes.formElement}>
        <TextField
          className={classes.formField}
          InputProps={{
            classes: {
              input: classes.formInput,
            },
          }}
          id="new-user-password"
          aria-describedby="new user password"
          type="password"
          placeholder="Şifre"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <div className={classes.formError}>
          {formik.errors.password && formik.touched.password && (
            <>
              <ErrorRoundedIcon className={classes.formErrorIcon} />
              {formik.errors.password}
            </>
          )}
        </div>
      </div>
      <Button className={classes.submitButton} type="submit">
        Kayıt Ol
      </Button>
      <Link className={classes.linkToSignin} to="/signin">
        <div className={classes.formElement}>
          <span className={classes.hasAlready}>Zaten hesabın var mı?</span>
        </div>
      </Link>
    </form>
  );
};

export default SignupForm;
