import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  Avatar,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import CreateAdIcon from '@mui/icons-material/AddRounded';

import { createErrorToast } from '@app-actions/toastActions';
import { getProject } from '@app-api/projects';
import SocialShareLinks from '@app-components/SocialShareLinks';
import Spinner from '@app-components/Spinner';
import TypographyBold from '@app-components/TypographyBold';
import { selectIsActiveSession } from '@app-redux/selectors/auth';
import { selectCurrentUserId } from '@app-redux/selectors/user';
import { formatISODate } from '@app-shared/utils';

import useStyles from './styles';

import AdsTable from './components/adsTable';
import CompleteProjectModal from './components/CompleteProjectModal';
import CreateAdModal from './components/createAdModal';

const Project = () => {
  const dispatch = useDispatch();

  const currentUserID = useSelector(selectCurrentUserId);

  const isActiveSession = useSelector(selectIsActiveSession);

  const { projectid } = useParams();

  const {
    data: { project, ads },
    isLoading,
    isFetching,
    isError,
  } = useQuery(['project', projectid], () => getProject(projectid), {
    initialData: { project: {}, ads: [] },
    refetchOnWindowFocus: false,
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const classes = useStyles();

  const renderCreateAdModal = (
    <CreateAdModal open={showCreateModal} handleOpen={setShowCreateModal} />
  );

  const renderCompleteProjectModal = (
    <CompleteProjectModal
      isOpen={showDeleteModal}
      setIsOpen={setShowDeleteModal}
    />
  );

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  if (isError) {
    dispatch(
      createErrorToast({
        message: 'Proje gösterilirken hata oluştu',
      }),
    );
  }

  const isOwnProject = currentUserID === project.createdBy._id;

  const getIsCompletedText = () => {
    if (isOwnProject) {
      return `Bu projeyi ${formatISODate(
        project.updatedAt,
      )} tarihinde sonlandırdınız. Projeye ilan eklemek için projeyi yeniden başlatmalısınız.`;
    }

    return `Bu proje ${project.createdBy.name} tarafından ${formatISODate(
      project.updatedAt,
    )} tarihinde sonlandırılmıştır.`;
  };

  return (
    <>
      {project && (
        <div className={classes.container}>
          <div className={classes.projectInfo}>
            <div>
              <Typography variant="h4">{project.category}</Typography>
              <Typography variant="h3">{project.title}</Typography>
              <Typography variant="h5">{project.location}</Typography>
              <SocialShareLinks type="project" id={projectid} />
            </div>
            <div>
              <Typography variant="h4">Projenin kurucusu</Typography>
              <div className={classes.line} />
              <Link
                className={classes.link}
                to={`/profile/${project.createdBy._id}`}
              >
                <div className={classes.founderInfo}>
                  <Typography variant="h4">{project.createdBy.name}</Typography>
                  <Avatar
                    src={project.createdBy.pic}
                    className={classes.userAvatar}
                    alt={project.createdBy.name}
                  />
                </div>
              </Link>
              {!isActiveSession ? (
                <Typography variant="h5">
                  İletişim bilgilerini görebilmek için lütfen giriş yapın
                </Typography>
              ) : (
                <>
                  <Typography variant="h5">
                    <div className={classes.userInfoLabel}>E-posta: </div>
                    {project.createdBy.email}
                  </Typography>
                  {project.createdBy?.pnumber && (
                    <Typography variant="h5">
                      <div className={classes.userInfoLabel}>Telefon: </div>
                      {project.createdBy.pnumber}
                    </Typography>
                  )}
                </>
              )}
            </div>
          </div>

          {project.logline && (
            <div className={classes.logline}>
              <Typography variant="h4" align="center">
                {project.logline}
              </Typography>
            </div>
          )}
          <div className={classes.section}>
            <TypographyBold variant="h2" align="center">
              Aranan Pozisyonlar
            </TypographyBold>
            <div className={classes.tabletopLine} />
            {project?.isCompleted && (
              <Typography align="center" variant="h4">
                {getIsCompletedText()}
              </Typography>
            )}
            {!project?.isCompleted && (
              <AdsTable ads={ads} isOwnProject={isOwnProject} />
            )}
          </div>

          {isOwnProject && !project?.isCompleted && (
            <IconButton
              onClick={() => setShowCreateModal(true)}
              className={classes.createAd}
            >
              <div className={classes.createAdButtonContainer}>
                <CreateAdIcon
                  fontSize="large"
                  className={classes.createAdIcon}
                />
                <Typography variant="h5">Yeni ilan ekle</Typography>
              </div>
            </IconButton>
          )}

          <div className={classes.section}>
            <TypographyBold variant="h2" align="center">
              Projenin Ayrıntıları
            </TypographyBold>
            <div className={classes.synopsisLine} />
            <TextField
              className={classes.synopsis}
              value={project.note}
              disabled
              multiline
              rows={16}
            />
          </div>

          {isOwnProject && !project?.isCompleted && (
            <Button
              className={classes.completeProjectButton}
              onClick={() => setShowDeleteModal(true)}
            >
              Projeyi Bitir
            </Button>
          )}
        </div>
      )}
      {isOwnProject && renderCreateAdModal}
      {isOwnProject && renderCompleteProjectModal}
    </>
  );
};

export default Project;
