import React from 'react';

import useStyles from './styles';

import Hero from './components/hero/index';
import SignupCard from './components/SignupCard';

const Signup = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Hero />
      <SignupCard />
    </div>
  );
};

export default Signup;
