import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: '20px',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(220, 220, 220, 0.1)',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginRight: '0',
      marginBottom: theme.spacing(4),
    },
  },
  link: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    border: '2px solid red',
  },
  cardHeader: {
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginBottom: theme.spacing(1),
  },
  cardSubtitles: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  userInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'start',
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  actionButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    borderRadius: '1rem',
    padding: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    border: `1px solid ${theme.palette.secondary.main}`,
    transition: 'all 0.4s',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));

export default useStyles;
