import axios from 'axios';
import { omit } from 'lodash';

import { API_CONSTANTS } from '@app-constants';

import { usersAxios } from '../axios/users';

const {
  USER: { GET_USER_CONFIG },
} = API_CONSTANTS;

export const getUserProfile = async ({ userId }) => {
  const response = await usersAxios.get(`/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const { data } = response;

  return data;
};

export const updateProfile = async ({ userId, newData }) => {
  const uploadData = { ...omit(newData, ['pic']) };

  if (newData.pic) {
    const cloudinaryData = await axios.post(
      `${
        process.env.REACT_APP_CLOUDINARY_URI ||
        'https://api.cloudinary.com/v1_1/junglenjungle'
      }/image/upload`,
      newData.pic,
    );

    const pic = cloudinaryData?.data?.secure_url || cloudinaryData?.data?.url;

    Object.assign(uploadData, { pic });
  }

  const response = await usersAxios.put(
    '',
    { user: { _id: userId, ...uploadData } },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  const { data } = response;

  return data;
};

const queryKeysGetUser = ['action', 'query', 'preview', 'pageParam'];

const getPaginationByPage = page => {
  if (page < 1) {
    return null;
  }

  if (page === 1) {
    return { offset: 0, limit: 50 };
  }

  return {
    offset:
      GET_USER_CONFIG.INITIAL_LENGTH +
      (page - 2) * GET_USER_CONFIG.USERS_PER_PAGE,
    limit: GET_USER_CONFIG.USERS_PER_PAGE,
  };
};

export const getUsers = async (req = {}) => {
  // get params in an object called 'req',
  // add keys of that req object to params object if they
  // are included in queryKeysGetUser
  const params = queryKeysGetUser.reduce((acc, current) => {
    if (req[current]) {
      if (current === 'pageParam') {
        Object.assign(acc, getPaginationByPage(req.pageParam));
      }
      // eslint-disable-next-line no-param-reassign
      acc[current] = req[current];
    }

    return acc;
  }, {});
  const response = await usersAxios.get('', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    params,
  });

  const { data } = response;

  return data;
};
