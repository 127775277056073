import types from './types';

export const setUser = userData => ({
  type: types.SET_USER,
  userData,
});

export const updateUserRequest = ({ userId, newData }) => ({
  type: types.UPDATE_USER_REQUEST,
  userId,
  newData,
});

export const updateUserSuccess = userData => ({
  type: types.UPDATE_USER_SUCCEEDED,
  userData,
});

export const updateUserFail = error => ({
  type: types.UPDATE_USER_FAILED,
  error,
});

export default {
  setUser,
  updateUserRequest,
  updateUserSuccess,
  updateUserFail,
};
