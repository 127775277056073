import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  searchSection: {
    padding: theme.spacing(12),
    paddingBottom: theme.spacing(2),
    position: 'sticky',
    top: '0',
    backgroundColor: theme.palette.common.white,
    zIndex: 999,
    boxShadow: props => (!props.searchMode ? 'none' : '2px 1px 4px #888'),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8),
      paddingBottom: theme.spacing(2),
    },
  },
  main: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  searchField: {
    '& .MuiInputBase-input': {
      fontSize: `${theme.typography.h4.fontSize} !important`,
    },
    '& input': {
      border: 'none !important',
      borderBottom: 'none !important',
      boxShadow: 'none !important',
      margin: '0 !important',
    },
    width: '80%',
    backgroundColor: lighten(theme.palette.tertiary.main, 0.75),
    borderRadius: '20px',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    marginLeft: '10%',
    [theme.breakpoints.down('md')]: {
      width: '94%',
      marginLeft: `calc(3% - ${theme.spacing(1) / 2}px)`,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: '0',
      '& .MuiInputBase-input': {
        fontSize: `${theme.typography.body1.fontSize} !important`,
      },
    },
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    marginLeft: '10%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '94%',
      marginLeft: '3%',
      padding: '4px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  avatar: {
    width: '48px',
    height: '48px',
    [theme.breakpoints.down('md')]: {
      width: '20px',
      height: '20px',
    },
  },
  user: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    '&:hover': {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.6),
    },
    '&:nth-of-type(odd)': {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.8),
      '&:hover': {
        backgroundColor: lighten(theme.palette.tertiary.main, 0.6),
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  userGrid: {
    width: '50%',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 3fr',
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      gridTemplateColumns: '1fr 3fr 4fr',
      padding: theme.spacing(1),
    },
  },
  userAvatar: {
    gridColumn: '1 / span 1',
  },
  userName: {
    gridColumn: '2 / span 1',
    textAlign: 'center',
  },
  userJob: {
    gridColumn: '3 / span 1',
    textAlign: 'center',
  },
  link: {
    '& > *': {
      color: '#000 !important',
    },
  },
  linkToNetwork: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h4.fontSize,
    textAlign: 'center',
    margin: theme.spacing(6),
    width: '40%',
    borderRadius: '20px',
    padding: theme.spacing(4),
    transition: 'all 0.4s',

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff !important',
    },

    '&:visited, &:active': {
      color: theme.palette.secondary.main,
    },
  },
  title: {
    margin: theme.spacing(2),
  },
}));

export default useStyles;
