import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  carousel: {
    marginLeft: theme.page.layout.paddingLeft,
    marginRight: theme.page.layout.paddingLeft,
    margin: '2rem',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  carouselItem: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '25%',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginTop: '1rem',
    },
  },
  carouselText: {
    textAlign: 'center',
    display: 'block',
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(1),
  },
  carouselLink: {
    color: '#000',
    userDrag: 'none',
  },
  carouselItemAvatar: {
    display: 'block',
    width: theme.spacing(16),
    height: theme.spacing(16),
    pointerEvents: 'none',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
}));

export default useStyles;
