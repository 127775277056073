const actionTypes = {
  // Authentication
  CHECK_SESSION_REQUEST: 'CHECK_SESSION_REQUEST',

  SIGNIN_REQUEST: 'SIGNIN_REQUEST',
  SIGNIN_SUCCEEDED: 'SIGNIN_SUCCEEDED',
  SIGNIN_FAILED: 'SIGNIN_FAILED',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCEEDED: 'SIGNUP_SUCCEEDED',
  SIGNUP_FAILED: 'SIGNUP_FAILED',

  LOGOUT: 'LOGOUT',

  // User
  GET_USER: 'GET_USER',
  SET_USER: 'SET_USER',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCEEDED: 'UPDATE_USER_SUCCEEDED',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',

  UPDATE_USER_PIC_REQUEST: 'UPDATE_USER_PIC_REQUEST',
  UPDATE_USER_PIC_SUCCEEDED: 'UPDATE_USER_PIC_SUCCEEDED',
  UPDATE_USER_PIC_FAILED: 'UPDATE_USER_PIC_FAILED',

  // Toasts
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
};

export default actionTypes;
