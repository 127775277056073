import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const TypographyBold = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.item} {...rest}>
      {children}
    </Typography>
  );
};

export default TypographyBold;
