import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  createErrorToast,
  createSuccessToast,
} from '@app-actions/toastActions';
import { completeProject } from '@app-api/projects';
import { ConfirmationModal } from '@app-components/ConfirmationModal';
import Spinner from '@app-components/Spinner';

const CompleteProjectModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { projectid } = useParams();

  const {
    mutate: completeProjectRequest,
    isLoading,
    isError,
    error,
    reset,
  } = useMutation(completeProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project', projectid]);
      dispatch(
        createSuccessToast({
          message: 'Proje başarıyla sonlandırıldı',
        }),
      );

      setIsOpen(false);
    },
    onError: () => {
      setIsOpen(false);
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    dispatch(
      createErrorToast({
        message: error.response.data?.message?.tr
          ? error.response.data.message.tr
          : 'Hata',
      }),
    );

    reset();
  }

  const handleConfirm = () => {
    completeProjectRequest(projectid);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
      title="Projeyi bitirmek istediğinize emin misiniz?"
      text="Projeye bağlı tüm ilanlar silinecektir."
    />
  );
};

export default CompleteProjectModal;
