import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import TypographyBold from '@app-components/TypographyBold';

import useStyles from './styles';

const AdsTable = ({ ads, isOwnProject }) => {
  const [rows, setRows] = useState([]);

  const classes = useStyles();

  const createRow = ({ category, lastday, _id }) => ({
    category,
    lastday,
    _id,
  });

  useEffect(() => {
    if (ads) {
      const newRows = [];
      ads.forEach(ad => {
        newRows.push(createRow(ad));
      });
      setRows(newRows);
    }
  }, [ads]);

  const getNoAdsText = () => {
    if (isOwnProject) {
      return 'Bu proje için hiç ilan oluşturmadın';
    }

    return 'Bu proje için henüz ilan oluşturulmadı';
  };

  return (
    <>
      {ads.length ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.category} align="center">
                <TypographyBold variant="h4">Kategori</TypographyBold>
              </TableCell>
              <TableCell align="center" className={classes.lastDay}>
                <TypographyBold variant="h4">Son başvuru günü</TypographyBold>
              </TableCell>
              <TableCell className={classes.goToAd} align="center">
                <TypographyBold variant="h4">ilan sayfası</TypographyBold>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow className={classes.tableRow} key={row._id}>
                <TableCell className={classes.category}>
                  <Typography variant="h4" align="center">
                    {row.category}
                  </Typography>
                </TableCell>
                <TableCell className={classes.lastDay}>
                  <Typography variant="h4" align="center">
                    {row.lastday}
                  </Typography>
                </TableCell>
                <TableCell className={classes.goToAd} align="center">
                  <Link to={`/ads/${row._id}`}>
                    <Button className={classes.goToAdButton}>ilani gör</Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography className={classes.noAdsText} align="center" variant="h4">
          {getNoAdsText()}
        </Typography>
      )}
    </>
  );
};

export default AdsTable;
