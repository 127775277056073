import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  aside: {
    position: 'sticky',
    top: theme.spacing(10),
  },
  card: {
    borderRadius: '20px',
    margin: theme.spacing(2),
    marginBottom: '0',
  },
  cardTitle: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  cardContent: {
    padding: '0 !important',
  },
  userCard: {
    display: 'flex',
    margin: '0',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    justifyContent: 'space-between',
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'start',
    flexGrow: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  link: {
    color: '#000',
  },
  goToSearchLink: {
    color: `${theme.palette.secondary.main} !important`,
    width: '100%',
    display: 'flex',
    margin: '0',
  },
  showMoreText: {
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
}));

export default useStyles;
