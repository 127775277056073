import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    width: '80%',
    marginLeft: '10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: theme.spacing(4),
    '& > *': {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      width: '94%',
      marginLeft: '3%',
      marginTop: theme.spacing(6),
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  },
  projectInfo: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      '& > div': {
        justifyContent: 'center',
        '& > *:not(:last-child)': {
          margin: '4px !important',
        },
      },
    },
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#000',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  tabletopLine: {
    width: '100%',
    height: '1px',
    backgroundColor: '#000',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
    },
  },
  founderInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfoLabel: {
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  userAvatar: {
    [theme.breakpoints.down('md')]: {
      width: '20px',
      height: '20px',
      marginLeft: theme.spacing(2),
    },
  },
  link: {
    textDecoration: 'none',
    color: '#000000DE',
  },
  logline: {
    backgroundColor: lighten(theme.palette.tertiary.main, 0.75),
    borderRadius: '20px',
    padding: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  synopsisLine: {
    width: '100%',
    height: '1px',
    backgroundColor: '#000',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  createAd: {
    fontSize: theme.typography.h4.fontSize,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    color: '#000',
  },
  createAdIcon: {
    color: '#000',
    fontSize: `${theme.typography.h3.fontSize} !important`,
    [theme.breakpoints.down('md')]: {
      fontSize: `${theme.typography.h5.fontSize} !important`,
    },
  },
  createAdButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  synopsis: {
    '& .MuiInputBase-root': {
      fontSize: theme.typography.h4.fontSize,
      paddingLeft: theme.spacing(2),
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottom: 'none',
    },
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    width: '100%',
    '& .MuiInputBase-input': {
      color: '#444',
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiInputBase-root': {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
  section: {
    width: '100%',
  },
  completeProjectButton: {
    marginLeft: 'auto',
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightNormal,
    borderRadius: '10px',
    border: `1px solid ${theme.palette.secondary.main}`,
    textTransform: 'none',
    color: theme.palette.secondary.main,
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
}));

export default useStyles;
