import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, TextField, Typography } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { useFormik } from 'formik';

import { signinRequest } from '@app-actions/authActions';

import useStyles from './styles';
import SigninSchema from './validationSchema';

const SigninForm = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: SigninSchema,
    onSubmit: values => {
      const formValues = values;
      dispatch(signinRequest(formValues));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
      <div className={classes.formElement}>
        <Typography className={classes.formTitle}>
          Beraber çalışacağın <br className={classes.titleBreak} />{' '}
          profesyonellere ulaş
        </Typography>
      </div>
      <div className={classes.formElement}>
        <TextField
          className={classes.formField}
          InputProps={{
            classes: {
              input: classes.formInput,
            },
          }}
          id="user-mail"
          aria-describedby="user email"
          value={formik.values.email}
          name="email"
          placeholder="E-posta"
          onChange={formik.handleChange}
        />
        <div className={classes.formError}>
          {formik.errors.email && formik.touched.email && (
            <>
              <ErrorRoundedIcon className={classes.formErrorIcon} />
              {formik.errors.email}
            </>
          )}
        </div>
      </div>
      <div className={classes.formElement}>
        <TextField
          className={classes.formField}
          InputProps={{
            classes: {
              input: classes.formInput,
            },
          }}
          id="user-password"
          aria-describedby="user password"
          value={formik.values.password}
          name="password"
          type="password"
          placeholder="Şifre"
          onChange={formik.handleChange}
        />
        <div className={classes.formError}>
          {formik.errors.password && formik.touched.password && (
            <>
              <ErrorRoundedIcon className={classes.formErrorIcon} />
              {formik.errors.password}
            </>
          )}
        </div>
      </div>
      <div className={classes.formElement}>
        <Typography variant="body1" className={classes.forgotPassword}>
          Şifreni mi unuttun?
        </Typography>
      </div>
      <Button type="submit" className={classes.formSubmit}>
        Giriş Yap
      </Button>
      <br />
      <Link to="/signup">
        <div className={classes.formElement}>
          <Typography variant="h4" className={classes.noAccount}>
            Hesabın yok mu?
          </Typography>
        </div>
      </Link>
    </form>
  );
};

export default SigninForm;
