import axios from '../axios/signup';

export const sendSignupRequest = async body => {
  const response = await axios.post('', JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { data } = response;

  return data;
};
