import React, { useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

import About from './about';
import Projects from './projects';

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: theme.palette.common.black,
  },
}));

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[700],
    fontSize: theme.typography.h3.fontSize,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '&.Mui-selected': {
      color: theme.palette.common.black,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  }),
);

const MainPanel = ({ about, name, isOwnProfile, projects }) => {
  const [tab, setTab] = useState('projects');

  const handleTabChange = (_event, newValue) => {
    setTab(newValue);
  };

  return (
    <main>
      <br />
      <TabContext value={tab}>
        {/* <nav> */}
        <StyledTabs
          value={tab}
          onChange={handleTabChange}
          aria-label="user information tabs"
          centered
        >
          <StyledTab label="Projeler" value="projects" />
          <StyledTab label="Hakkında" value="about" />
        </StyledTabs>
        {/* </nav> */}
        <br />
        <TabPanel value="projects">
          <Projects data={projects} />
        </TabPanel>
        <TabPanel value="about">
          <About isOwnProfile={isOwnProfile} aboutUser={about} name={name} />
        </TabPanel>
      </TabContext>
    </main>
  );
};

export default MainPanel;
