import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    borderBottom: '0.2rem solid transparent',
    borderRadius: '0',
    background: 'none',
    backgroundColor: 'transparent',
    color: '#fff',
    textTransform: 'none',
    transition: 'background 10000s',
    '&:hover, &.Mui-focusVisible': {
      borderBottom: `0.2rem solid ${theme.palette.secondary.main}`,
      background: 'none',
    },
    '& MuiButton-label': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
      marginRight: '-2px',
    },
  },
  appbar: {
    paddingLeft: theme.page.layout.paddingLeft,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10px',
    },
  },
  title: {
    fontFamily: 'Courier',
    color: theme.palette.common.white,
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  titleContainer: {
    gridColumn: '1 / span 1',
  },
  searchbar: {
    paddingLeft: theme.spacing(1),
  },
  searchText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: `${theme.typography.body1.fontSize} !important`,
      marginRight: theme.spacing(0.4),
    },
  },
  user: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: lighten(theme.palette.tertiary.main, 0.9),
    '&:hover': {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.6),
    },
    '&:nth-of-type(odd)': {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.8),
      '&:hover': {
        backgroundColor: lighten(theme.palette.tertiary.main, 0.6),
      },
    },
  },
  userGrid: {
    width: '60%',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 3fr',
  },
  userAvatar: {
    gridColumn: '1 / span 1',
  },
  userName: {
    gridColumn: '2 / span 1',
  },
  userJob: {
    gridColumn: '3 / span 1',
  },
  link: {
    '& > *': {
      color: '#000 !important',
    },
  },
  results: {
    position: 'absolute',
    zIndex: '10',
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    marginLeft: '10%',
  },

  hamburgerMenu: {
    '& > *': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  hamburgerMenuContainer: {
    display: isSmallScreen => (isSmallScreen ? 'inline-block' : 'none'),
    margin: 0,
    marginBottom: '0.2rem',
    position: 'relative',
  },
  authButtons: {
    paddingRight: theme.spacing(0),
  },
}));

export default useStyles;
