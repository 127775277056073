import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';

import { createErrorToast } from '@app-actions/toastActions';
import { getAllProjects } from '@app-api/projects';
import Spinner from '@app-components/Spinner';
import { calcDaysBetween } from '@app-utils';

import useStyles from './styles';

const ProjectCard = () => {
  const dispatch = useDispatch();

  const {
    data: { projects },
    isLoading,
    isError,
  } = useQuery('allProjects', getAllProjects, {
    initialData: { projects: [] },
  });

  const classes = useStyles();

  const renderUserInfo = item => {
    // WARNING: DO NOT TOUCH
    const today = new Date();
    const todaysDate = `${
      today.getMonth() + 1
    }/${today.getDate()}/${today.getFullYear()}`;
    const createdDateElems = item.createdAt.split('-');
    const createdDate = `${createdDateElems[1]}/${
      createdDateElems[2].split('T')[0]
    }/${createdDateElems[0]}`;
    const daysBetween = calcDaysBetween(todaysDate, createdDate);

    return (
      <div className={classes.userInfoGrid}>
        <Typography variant="h5" className={classes.userInfoName}>
          {item?.createdBy?.name}
        </Typography>
        <Typography variant="h6" className={classes.userInfoJob}>
          {item?.createdBy?.job}
        </Typography>
        <Link
          className={classes.userInfoPic}
          to={`/profile/${item.createdBy._id}`}
        >
          <Avatar src={item.createdBy.pic} />
        </Link>
        <Typography variant="h6" className={classes.userInfoDaysBetween}>
          {daysBetween > 1
            ? `${daysBetween} gün önce`
            : daysBetween === 1
            ? 'Dün'
            : 'Bugün'}
        </Typography>
      </div>
    );
  };

  const renderCards = () => {
    if (projects) {
      return projects.map(item => (
        <Card className={classes.card} key={item._id} raised>
          <CardHeader
            className={classes.cardHeader}
            title={item.category}
            titleTypographyProps={{
              variant: 'h4',
              className: classes.cardHeaderTitle,
            }}
            subheader={item.title}
            subheaderTypographyProps={{
              variant: 'h4',
              className: classes.cardHeaderTitle,
            }}
            action={renderUserInfo(item)}
          />
          <hr className={classes.line} />
          <CardContent>
            <Typography align="center" variant="h5">
              {item.logline}
            </Typography>
          </CardContent>
          <hr className={classes.line} />
          <CardActions className={classes.cardActions}>
            <Link to={`/project/${item._id}`}>
              <Button className={classes.actionButton}>Projeyi İncele</Button>
            </Link>
          </CardActions>
        </Card>
      ));
    }

    return [];
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    dispatch(
      createErrorToast({
        message: 'Projeler görüntülenirken hata oluştu',
      }),
    );
  }

  return <div>{renderCards()}</div>;
};

export default ProjectCard;
