import React from 'react';

import { Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import TypographyBold from '@app-components/TypographyBold';

import useStyles from './styles';

const ContactModal = ({ open, handleOpen, user }) => {
  const { email, pnumber } = user;

  const classes = useStyles();

  const handleClose = () => {
    handleOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent>
          <TypographyBold variant="h3">Telefon numarası</TypographyBold>
          <hr />
          {!user.hidePnumber && <Typography variant="h3">{pnumber}</Typography>}
          <br />
          <br className={classes.spacing} />
          <br />
          <TypographyBold variant="h3">E-posta</TypographyBold>
          <hr />
          <Typography variant="h3">{email}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Kapat</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactModal;
