import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: props => ({
    display: 'flex',
    width: props.isOwnProfile ? '88%' : '75%',
    marginLeft: props.isOwnProfile ? '10%' : '4%',
    justifyContent: 'center',
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(8),
      justifyContent: props.isOwnProfile ? 'center' : 'start',
      marginLeft: props.isOwnProfile ? '6%' : '12%',
    },
  }),
  userInfoContainer: (props) => ({
    marginLeft: props.isOwnProfile ? 'auto' : '',
    display: 'flex',
  }),
  avatar: {
    display: 'block',
    width: '132px',
    height: '132px',
    [theme.breakpoints.down('md')]: {
      width: '36px',
      height: '36px',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    }
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  editButton: (props) => ({
    display: props.isOwnProfile ? 'inline-block' : 'none',
    justifySelf: 'end',
    alignSelf: 'end',
    fontSize: theme.typography.h5.fontSize,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.tertiary.main,
    textTransform: 'none',
    padding: 'none',
    color: '#fff',
    border: `0.2rem solid transparent`,
    borderRadius: '100px',
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.tertiary.main,
      border: `0.2rem solid ${theme.palette.tertiary.main}`,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
      border: `none`,
      marginBottom: theme.spacing(1),
    },
  }),
  contactContainer: {
    display: 'flex',
  },
  contactButton: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  line: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
