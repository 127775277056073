import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import useStyles from './styles';

const Spinner = () => {
  const classes = useStyles();

  return <CircularProgress className={classes.spinner} />;
};

export default Spinner;
