import { push } from 'connected-react-router';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { sendSigninRequest } from '@app-api/signin';

import actions from '../actions';
import types from '../actions/types';

const { authActions, userActions } = actions;

function* checkSessionRequest() {
  try {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    // might do a better check here than checking just for existence
    // e.g. we can check token's validity when BE provides an endpoint for it
    if (token && user) {
      yield put(authActions.signinSuccess({ user, token }));
    } else {
      yield put(authActions.logout());
    }
  } catch (error) {
    yield put(authActions.logout());
  }
}

function* onCheckSessionSuccess({ user }) {
  yield put(userActions.setUser(user));
}

function* signinRequest({ email, password }) {
  try {
    const data = yield call(sendSigninRequest, { email, password });
    yield put(authActions.signinSuccess(data));
  } catch (error) {
    yield put(authActions.signinFail(error));
  }
}

function* onSigninRequestSuccess({ userData }) {
  const { user, token } = userData;
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  yield put(userActions.setUser(user));
  yield put(push('/ads'));
}

function* logoutRequest() {
  localStorage.clear();
  yield put(userActions.setUser(null));
  yield put(push('/signin'));
}

function* watchCheckSession() {
  yield takeLatest(types.CHECK_SESSION_REQUEST, checkSessionRequest);
}

function* watchCheckSessionSuccess() {
  yield takeLatest(types.CHECK_SESSION_REQUEST, onCheckSessionSuccess);
}

function* watchUserSignin() {
  yield takeLatest(types.SIGNIN_REQUEST, signinRequest);
}

function* watchUserSigninSuccess() {
  yield takeLatest(types.SIGNIN_SUCCEEDED, onSigninRequestSuccess);
}

function* watchUserLogout() {
  yield takeLatest(types.LOGOUT, logoutRequest);
}

function* authSaga() {
  yield all([
    fork(watchCheckSession),
    fork(watchCheckSessionSuccess),
    fork(watchUserSignin),
    fork(watchUserSigninSuccess),
    fork(watchUserLogout),
  ]);
}

export default authSaga;
