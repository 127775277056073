import { createTheme } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  palette: {
    primary: {
      main: '#003249', // Prussian Blue
    },
    secondary: {
      main: '#007EA7', // CG Blue
    },
    tertiary: {
      main: '#80CED7', // Middle Blue
    },
  },
  page: {
    layout: {
      paddingLeft: '176px',
      paddingRight: '176px',
    },
  },
});

theme = createTheme(theme, {
  typography: {
    fontWeightBold: 600,
    fontWeightRegular: 400,

    h1: {
      fontSize: '64px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
    },
    h2: {
      fontSize: '48px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
    },
    h3: {
      fontSize: '36px',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
    h4: {
      fontSize: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    h5: {
      fontSize: '18px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    h6: {
      fontSize: '16px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
  },
});

export default theme;
