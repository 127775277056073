import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Lütfen isminizi giriniz.'),
  email: Yup.string()
    .email('Lütfen geçerli bir e-posta adresi giriniz.')
    .required('Lütfen e-posta adresinizi giriniz.'),
  password: Yup.string()
    .min(6, 'Şifreniz en az 6 karakterden oluşmalıdır.')
    .required('Lütfen şifrenizi seçiniz'),
});

export default SignupSchema;
