import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  spacing: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
