import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Link } from 'react-router-dom';

import { Avatar, Typography } from '@material-ui/core';

import TypographyBold from '@app-components/TypographyBold';
import { getShortenedName } from '@app-utils';

import useStyles from './styles';

const CustomCarousel = ({ items }) => {
  const classes = useStyles();

  const getProfileAsCarouselItem = profileData => (
    <div
      className={classes.carouselItem}
      key={profileData._id}
      onDrag={e => e.preventDefault()}
      onDragOver={e => e.preventDefault()}
      onDrop={e => e.preventDefault()}
      onDragEnd={e => e.preventDefault()}
    >
      <Link className={classes.carouselLink} to={`/profile/${profileData._id}`}>
        <Avatar
          src={profileData.pic}
          className={classes.carouselItemAvatar}
          alt={`${profileData.name} profili. Görüntülemek için giriş yapın.`}
        />
        <Typography
          variant="h4"
          align="center"
          className={classes.carouselText}
        >
          {getShortenedName(profileData.name)}
        </Typography>
        <TypographyBold
          variant="h4"
          align="center"
          className={classes.carouselText}
        >
          {profileData.job}
        </TypographyBold>
      </Link>
    </div>
  );

  const renderMiniProfiles = () =>
    items ? (
      items?.map(getProfileAsCarouselItem)
    ) : (
      <h3>Kullanıcı bulunamadı</h3>
    );

  const breakpoints = [
    { width: 1, itemsToShow: 3 },
    { width: 540, itemsToShow: 4 },
    { width: 700, itemsToShow: 5 },
    { width: 1450, itemsToShow: 6 },
    { width: 2000, itemsToShow: 7 },
  ];

  return (
    <div className={classes.carousel}>
      <Carousel
        itemsToShow={7}
        enableAutoPlay
        showArrows={false}
        pagination={false}
        breakPoints={breakpoints}
      >
        {renderMiniProfiles()}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
