import * as Yup from 'yup';

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email('Lütfen geçerli bir e-posta adresi girin.')
    .required('E-posta alanı zorunludur.'),
  password: Yup.string().required('Lütfen şifrenizi giriniz.'),
});

export default SigninSchema;
