import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import Creatable from 'react-select/creatable';

import { searchJobs } from '@app-api/constants';
import {
  convertValuesToOptions,
  convertValueToOptions,
} from '@app-shared/utils';

import { customSelectStyles, useStyles } from '../styles';

const SelectJob = ({ onSelect, value, isMulti, extraClass, ...otherProps }) => {
  const [jobSearchQuery, setJobSearchQuery] = useState(null);
  const [selectedJob, setSelectedJob] = useState(
    convertValueToOptions(value) || null,
  );

  const classes = useStyles();

  const {
    data: { jobTitles: jobSearchResult },
  } = useQuery(
    ['jobs', jobSearchQuery],
    () => searchJobs({ query: jobSearchQuery }),
    {
      initialData: { jobTitles: [] },
      refetchOnWindowFocus: false,
      enabled: !!jobSearchQuery,
    },
  );

  const options = useMemo(
    () => convertValuesToOptions(jobSearchResult),
    [jobSearchResult],
  );

  const handleSelectJob = job => {
    setSelectedJob(job);

    if (typeof onSelect === 'function') {
      if (isMulti) {
        onSelect(job);
      } else {
        onSelect(job?.value);
      }
    }
  };

  const handleJobInputChange = input => {
    setJobSearchQuery(input);
  };

  const formatCreateLabel = text => `Oluştur "${text}"`;

  return (
    <Creatable
      className={`${classes.dropdown} ${extraClass}`}
      styles={customSelectStyles}
      value={selectedJob}
      onInputChange={handleJobInputChange}
      onChange={handleSelectJob}
      options={options}
      noOptionsMessage={() => null}
      placeholder="Birden fazla meslek seçebilirsin"
      formatCreateLabel={formatCreateLabel}
      isMulti={isMulti}
      isSearchable
      isClearable
      {...otherProps}
    />
  );
};

export default SelectJob;
