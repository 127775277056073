import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  '*': {
    fontFamily: 'Roboto',
  },
  container: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(8),
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    display: 'block',
  },
  motto: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}));

export default useStyles;
