import React from 'react';

import { Typography } from '@material-ui/core';

import useStyles from './styles';

const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h1" align="center" className={classes.title}>
        Jungle
      </Typography>
      <Typography variant="h1" align="center" className={classes.motto}>
        İnsanları bul, projeler seni bulsun
      </Typography>
    </div>
  );
};

export default Hero;
