import { all, fork } from 'redux-saga/effects';

import authSaga from './authSaga';
import errorSaga from './errorSaga';
import toastSaga from './toastSaga';
import userSaga from './userSaga';

function* rootSaga() {
  yield all([fork(authSaga), fork(errorSaga), fork(toastSaga), fork(userSaga)]);
}

export default rootSaga;
