import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  Avatar,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';

import { createErrorToast } from '@app-actions/toastActions';
import { deleteAd, getAd } from '@app-api/ads';
import { ConfirmationModal } from '@app-components/ConfirmationModal';
import SocialShareLinks from '@app-components/SocialShareLinks';
import Spinner from '@app-components/Spinner';
import TypographyBold from '@app-components/TypographyBold';
import { selectIsActiveSession } from '@app-selectors/auth';
import { selectCurrentUserId } from '@app-selectors/user';
import { daysAgo } from '@app-utils';

import useStyles from './styles';

const Ad = () => {
  const dispatch = useDispatch();

  const currentUserID = useSelector(selectCurrentUserId);
  const isActiveSession = useSelector(selectIsActiveSession);

  const { adid } = useParams();

  const queryClient = useQueryClient();

  const {
    isLoading: isAdLoading,
    isError: isAdError,
    data: { ad },
    error: adErrror,
  } = useQuery(['ad', adid], () => getAd({ adId: adid }), {
    initialData: { ad: {} },
    refetchOnWindowFocus: false,
  });

  const {
    mutate: deleteAdRequest,
    isLoading: isDeletePending,
    isError: isDeleteError,
    error: deleteError,
    reset: resetDeleteMutation,
  } = useMutation(deleteAd, {
    onSuccess: () => {
      const projectID = ad.postedOn._id;
      dispatch(push(`/project/${projectID}`));
      queryClient.invalidateQueries(['ad', adid]);
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isLargeScreen = useMediaQuery('(min-width:1220px)');
  const isOwnAd = !isEmpty(ad) && currentUserID === ad.postedBy._id;

  const classes = useStyles(isLargeScreen);

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteAd = () => {
    deleteAdRequest({ adId: adid });
  };

  if (isAdLoading || isDeletePending) {
    return <Spinner />;
  }

  if (isAdError) {
    dispatch(
      createErrorToast({
        message: adErrror.response.data.message.tr,
      }),
    );
  }

  if (isDeleteError) {
    dispatch(
      createErrorToast({
        message: deleteError.response.data.message.tr,
      }),
    );

    resetDeleteMutation();
  }

  return (
    <>
      {!isEmpty(ad) && (
        <div className={classes.container}>
          <div className={classes.adInfo}>
            <Typography
              align="center"
              className={classes.categoryHeader}
              variant="h3"
            >
              {ad.category}
              {' Aranıyor'}
            </Typography>

            <div className={classes.projectInfo}>
              <Typography variant="h3">{ad.postedOn.title}</Typography>
              <Typography variant="h4">{ad.postedOn.category}</Typography>
              <SocialShareLinks type="ad" id={adid} />
            </div>

            <div className={classes.userInfo}>
              <Typography variant="h4">İletişim</Typography>
              <div className={classes.line} />
              <div className={classes.founderInfo}>
                <Link
                  to={`/profile/${ad.postedBy._id}`}
                  className={classes.userNameLink}
                >
                  <Typography variant="h4">{ad.postedBy.name}</Typography>
                </Link>
                <Link to={`/profile/${ad.postedBy._id}`}>
                  <Avatar
                    src={ad.postedBy.pic}
                    className={classes.userAvatar}
                    alt={ad.postedBy.name}
                  />
                </Link>
              </div>
              {!isActiveSession ? (
                <Typography variant="h5">
                  İletişim bilgilerini görebilmek için lütfen giriş yapın
                </Typography>
              ) : (
                <>
                  <Typography variant="h5">
                    <div className={classes.userInfoLabel}>E-posta: </div>
                    {ad.postedBy.email}
                  </Typography>
                  <Typography variant="h5">
                    <div className={classes.userInfoLabel}>Telefon: </div>
                    {ad.pnumber}
                  </Typography>
                </>
              )}
            </div>
          </div>

          <div className={classes.generalInfo}>
            <div className={classes.adCardGrid}>
              <Typography className={classes.adInfoHeader} variant="h4">
                İlan bilgileri
              </Typography>
              <div className={classes.adCard}>
                <Typography variant="h4">{`${daysAgo(
                  ad.createdAt,
                )} gün önce yayınlandı`}</Typography>
                <Typography variant="h4">
                  {`Son başvuru günü: ${ad.lastday}`}
                </Typography>
                <Link
                  className={classes.inspectProjectLinkMobile}
                  to={`/project/${ad.postedOn._id}`}
                >
                  Projeyi incele
                </Link>
              </div>
            </div>

            <div className={classes.projectCardGrid}>
              <Typography className={classes.projectInfoHeader} variant="h4">
                Proje bilgileri
              </Typography>
              <div className={classes.projectCard}>
                <Typography variant="h4">{ad.postedOn.title}</Typography>
                <Typography variant="h4">{ad.postedOn.category}</Typography>
                <Link
                  className={classes.inspectProjectLink}
                  to={`/project/${ad.postedOn._id}`}
                >
                  <Button className={classes.inspectProjectButton}>
                    Projeyi incele
                  </Button>
                </Link>
              </div>
            </div>

            <div className={classes.noteTitle}>
              <TypographyBold variant="h3" align="center">
                Notlar
              </TypographyBold>
            </div>

            <div className={classes.notes}>
              <TextField
                className={classes.notesField}
                value={ad.note}
                disabled
                multiline
                rows={14}
              />
            </div>
          </div>

          {isOwnAd && (
            <Button
              className={classes.deleteAdButton}
              onClick={showDeleteModal}
            >
              İlanı Sil
            </Button>
          )}
          <ConfirmationModal
            isOpen={isDeleteModalOpen}
            handleCancel={hideDeleteModal}
            handleConfirm={handleDeleteAd}
            handleClose={hideDeleteModal}
            title="İlanı silmek istediğinize emin misiniz?"
            text="Bu işlem geri alınamaz"
          />
        </div>
      )}
    </>
  );
};

export default Ad;
