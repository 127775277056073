import React from 'react';

import { Box, Typography } from '@material-ui/core';

import useStyles from './styles';

const ProfileCompletion = ({ location, profilePic, primaryJob, about }) => {
  const classes = useStyles();

  return (
    <Box className={classes.completionBox}>
      <Typography variant="h3">
        Profilini tamamla, daha fazla kişiye ulaş.
      </Typography>
      <Typography variant="h5">
        Tamamlanmış profiller aramalarda ve tanıyor olabileceğin kişiler
        sayfasında daha fazla gözükür.
      </Typography>
      <Box className={classes.completionItem}>
        <div className={location ? classes.filledDot : classes.emptyDot} />
        <Typography variant="h4">Konum bilgilerini gir.</Typography>
      </Box>
      <Box className={classes.completionItem}>
        <div
          className={
            profilePic !== process.env.REACT_APP_DEFAULT_PIC_URI
              ? classes.filledDot
              : classes.emptyDot
          }
        />
        <Typography variant="h4">Profil resmini seç.</Typography>
      </Box>
      <Box className={classes.completionItem}>
        <div className={primaryJob ? classes.filledDot : classes.emptyDot} />
        <Typography variant="h4">
          Aramalarda görünmek istediğin mesleği seç.
        </Typography>
      </Box>
      <Box className={classes.completionItem}>
        <div className={about?.trim() ? classes.filledDot : classes.emptyDot} />
        <Typography variant="h4">Hakkında kısmını doldur.</Typography>
      </Box>
    </Box>
  );
};

export default ProfileCompletion;
