import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { updateProfile } from '@app-api/users';

import types from '../actions/types';
import {
  setUser,
  updateUserFail,
  updateUserSuccess,
} from '../actions/userActions';

function* updateUser({ userId, newData }) {
  try {
    const data = yield call(updateProfile, { userId, newData });
    yield put(updateUserSuccess(data));
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* onUpdateUserSuccess({ userData: { user } }) {
  localStorage.setItem('user', JSON.stringify(user));
  yield put(setUser(user));
}

function* watchUpdateUserRequest() {
  yield takeEvery(types.UPDATE_USER_REQUEST, updateUser);
}

function* watchUpdateUserSuccess() {
  yield takeEvery(types.UPDATE_USER_SUCCEEDED, onUpdateUserSuccess);
}

function* userSaga() {
  yield all([fork(watchUpdateUserRequest), fork(watchUpdateUserSuccess)]);
}

export default userSaga;
