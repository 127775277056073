import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { checkSessionRequest } from '@app-actions/authActions';
import Ad from '@app-pages/ad/Ad';
import Ads from '@app-pages/ads/Ads';
import CreateProject from '@app-pages/createProject';
import Profile from '@app-pages/profile/Profile';
import Project from '@app-pages/project/Project';
import Projects from '@app-pages/projects/Projects';
import SearchUsers from '@app-pages/searchUsers/SearchUsers';
import Signin from '@app-pages/signin/Signin';
import Signup from '@app-pages/signup/Signup';
import UsersYouMayKnow from '@app-pages/usersYouMayKnow';

const Routing = () => {
  const dispatch = useDispatch();

  return (
    <div style={{ minHeight: '84vh' }}>
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            localStorage.getItem('token') ? (
              <Redirect to="/ads" />
            ) : (
              <Redirect to="/signin" />
            )
          }
        />
        <Route path="/profile/:userid">
          <Profile />
        </Route>
        <Route path="/project/:projectid">
          <Project />
        </Route>
        <Route
          exact
          path="/projects"
          onEnter={() => {
            dispatch(checkSessionRequest());
          }}
        >
          <Projects />
        </Route>
        <Route path="/ads/:adid">
          <Ad />
        </Route>
        <Route
          exact
          path="/ads"
          onEnter={() => {
            dispatch(checkSessionRequest());
          }}
        >
          <Ads />
        </Route>
        <Route
          path="/createproject"
          onEnter={() => {
            dispatch(checkSessionRequest());
          }}
        >
          <CreateProject />
        </Route>
        <Route
          path="/searchusers"
          onEnter={() => {
            dispatch(checkSessionRequest());
          }}
        >
          <SearchUsers />
        </Route>
        <Route
          path="/network"
          onEnter={() => {
            dispatch(checkSessionRequest());
          }}
        >
          <UsersYouMayKnow />
        </Route>
        <Route exact path="/signin">
          <Signin />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
      </Switch>
    </div>
  );
};

export default Routing;
