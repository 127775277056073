import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Card,
  CardContent,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { createErrorToast } from '@app-actions/toastActions';
import { getUsers } from '@app-api/users';
import Spinner from '@app-components/Spinner';
import { API_CONSTANTS } from '@app-constants';

import useStyles from './styles';

const Suggestioncard = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const {
    data: { users },
    isLoading,
    isError,
  } = useQuery(
    'profileSuggestions',
    () =>
      getUsers({
        action: API_CONSTANTS.USER.GET_USER_ACTION_TYPES.YOU_MAY_KNOW,
        preview: true,
      }),
    {
      initialData: { users: [] },
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
    },
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    dispatch(
      createErrorToast({
        message: 'Tanıyor olabileceğiniz kişileri ararken hata oluştu.',
      }),
    );
  }

  const renderUsers = () =>
    users?.map(user => (
      <Link className={classes.link} key={user._id} to={`/profile/${user._id}`}>
        <Tooltip title="Profili görüntülemek için tıklayın" placement="bottom">
          <div className={classes.userCard}>
            <Avatar src={user.pic} alt="picture of user" />
            <div className={classes.userInfo}>
              <Typography variant="h5">{user.name}</Typography>
              <Typography variant="h5">{user.job}</Typography>
            </div>
          </div>
        </Tooltip>
      </Link>
    ));

  return (
    <aside className={classes.aside}>
      <Card className={classes.card} raised>
        <div className={classes.cardTitle}>
          <Typography variant="h4">Tanıyor Olabileceğin Kişiler</Typography>
        </div>
        <hr />
        <CardContent className={classes.cardContent}>
          {renderUsers()}
          <Link
            className={classes.goToSearchLink}
            key="go-to-search-link"
            to="/network"
          >
            <Typography variant="h5" className={classes.showMoreText}>
              Daha fazla göster
            </Typography>
          </Link>
        </CardContent>
      </Card>
    </aside>
  );
};

export default Suggestioncard;
