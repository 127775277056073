import React from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import useStyles from './styles';

const UserCard = ({ small, user }) => {
  const { _id, pic, name, job, email, pnumber, hidePnumber } = user;

  const classes = useStyles(small);

  return (
    <aside className={classes.aside}>
      <Card raised className={classes.card}>
        <Link to={`/profile/${_id}`} className={classes.link}>
          <div className={classes.cardHeader}>
            <Avatar className={classes.avatar} src={pic} alt="user picture" />
            <Typography className={classes.userName} variant="h4">
              {name}
            </Typography>
            <Typography className={classes.job} variant="h5">
              {job}
            </Typography>
          </div>
        </Link>
        {!small && (
          <>
            <hr />
            <CardContent className={classes.cardContent}>
              <Typography variant="h5">İletişim</Typography>
              <Typography variant="h6">{email}</Typography>
              {!hidePnumber && <Typography variant="h6">{pnumber}</Typography>}
            </CardContent>
          </>
        )}
        {small && (
          <CardContent className={classes.cardContent}>
            <Link to={`/profile/${_id}`}>
              <Button className={classes.button}>Profili İncele</Button>
            </Link>
          </CardContent>
        )}
      </Card>
    </aside>
  );
};

export default UserCard;
