import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  aside: {
    position: 'sticky',
    top: theme.spacing(10),
  },
  card: {
    borderRadius: '20px',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(220, 220, 220, 0.1)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  avatar: small => ({
    width: small ? '150px' : '5rem',
    height: small ? '150px' : '5rem',
    [theme.breakpoints.down('sm')]: {
      width: small ? '60px' : '5rem',
      height: small ? '60px' : '5rem',
    },
  }),
  userName: {
    textAlign: 'center',
    textAlignLast: 'center',
  },
  job: {
    textAlign: 'center',
    textAlignLast: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  link: {
    '& > *': {
      color: '#000 !important',
    },
  },
  button: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    fontSize: theme.typography.h5.fontSize,
    borderRadius: '10px',
    transition: 'all 0.4s',
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize,
      padding: '2px 4px',
    },
  },
}));

export default useStyles;
