import React from 'react';

import { Button } from '@material-ui/core';

import TypographyBold from '@app-components/TypographyBold';

import useStyles from './styles';

const JobTitles = ({ data }) => {
  const classes = useStyles();

  const renderTitles = () =>
    data.map(title => (
      <Button className={classes.button} key={title}>
        {title}
      </Button>
    ));

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          <TypographyBold variant="h1">
            Jungle&apos;da neler bulabilirsin?
          </TypographyBold>
        </div>
        <div className={classes.buttons}>{renderTitles()}</div>
      </div>
    </>
  );
};

export default JobTitles;
